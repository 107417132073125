import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  personalInformationSaved:boolean;
  intention: string;
  partnerDetails: string;
  why_you_want_join: string;
  openPopup: boolean;
  error: string;
  word_partner: number;
  word_location: number;
  word_team: number;
  feedbackText:string;
  feedbackEmoji:string;
  formSubmitted:boolean;
  open:boolean;
  closePopup:boolean;
  isFeedBackFormOpen:boolean;
  isformSubmittedPopupOpen:boolean;
  isSignInToContinuePopupOpen:boolean;
  isLoggedIn: boolean;
  charCount: number;
  errorToshow: string;
  isActive:boolean;
  isActive2:boolean;
  cityError:string;
  userTypeError: string;
  formSubmissionError:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  feedBackApiCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.handleFeedbackChange =  this.handleFeedbackChange.bind(this);
    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      personalInformationSaved: false,
      intention:'',
      partnerDetails:'',
      why_you_want_join:'',
      openPopup:false,
      error:'',
      word_partner: 0,
      word_location: 0,
      word_team: 0,
      closePopup:false,
      feedbackEmoji:"",
      open:false,
      feedbackText:"",
      formSubmitted:false,
      isFeedBackFormOpen:false,
      isformSubmittedPopupOpen:false,
      isSignInToContinuePopupOpen: false,
      isLoggedIn: !!localStorage.getItem('authToken'),
      charCount: 0,
      errorToshow:"",
      isActive:false,
      isActive2:false,
      cityError:"",
      userTypeError: "",
      formSubmissionError:"",
  // Customizable Area E
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.feedBackSubmitted(responseJson)
          this.setState({ loading: false });
          if(responseJson.errors.phone_number){
          this.parseApiCatchErrorResponse(responseJson.errors?.phone_number[0]);
          }else if (responseJson.errors.email){
            this.parseApiCatchErrorResponse(responseJson.errors?.email[0]);
          } else if(responseJson.errors[0].token){
            if(apiRequestCallId === this.feedBackApiCallId){
              this.showAlert('token','expired')
            }
          }

        } 
        this.restReceiveFunction(apiRequestCallId,responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }

  restReceiveFunction=(apiRequestCallId:string,responseJson:any)=>{
    if (apiRequestCallId == this.getUserListApiCallId) {
      let filterData = responseJson.data.map((item: ResponseJson) => {
        return {
          userId: item.id,
          firstName: item.attributes.first_name,
          lastName: item.attributes.last_name,
          phoneNumber: item.attributes.phone_number,
          email: item.attributes.email,
          organization: item.attributes.organization,
          teamName: item.attributes.team_name,
          userType: item.attributes.i_am,
          rating: item.attributes.stars_rating.toString(),
          gender: item.attributes.gender,
          address: item.attributes.address,
          country: item.attributes.country,
          state: item.attributes.state,
          city: item.attributes.city,
          file: item.attributes.file.file_name,
        };
      });
      this.setState({ filterData }, () => {
        this.onChangeHandler(0);
      });
    } else if (apiRequestCallId == this.addUserApiCallId) {
      this.submitFormReceive(responseJson)
    } else if (apiRequestCallId === this.feedBackApiCallId){
      this.feedBackSubmitted(responseJson)
    }
  }


  submitFormReceive=(responseJson:any)=>{
    if(responseJson.errors){
      const [key, messages]:any = Object.entries(responseJson.errors)[0]
      this.setState({formSubmissionError:`${key} ${messages.join(", ")}`})
    } else{
      toast.success("User added successfully!");
      this.setState({ openDialogName: "" });
      this.onCancel();
      this.handleOpenPopup()
    }
  }
  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  };

  onFileChange = (file: File | null) => {
    this.setState({ file });
    if (this.state.openDialogName === "Edit") {
      this.setState({ isChange: true });
    }
  };

  feedBackSubmitted=(responseJson:{errors?:string[],data?:{id:string,type:string,attributes:{feedback_type:string,feedback:string, user_name:string|null}}})=>{
    if(responseJson.errors){
      this.setState({errorToshow:responseJson.errors[0]})
    }else if(responseJson.data){
    this.closeFeedBackPopup()
      this.openFeedbackSubmittedPopup()
    }
  }

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit",
    });
  };

  onCancel = () => {
    this.setState({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      personalInformationSaved: false,
      intention:'',
      partnerDetails:'',
      why_you_want_join:'',
      openPopup:false,
    });
  };

  getUserList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    formData.append("form[file]", this.state.file as Blob);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accessToken"),
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    if (this.state.isChange) {
      formData.append("form[file]", this.state.file as Blob);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleInputChange = ( fieldName: keyof S,value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.closeDropdown()
    this.setState(updatedState as S)
  }

  disableNextButton=():boolean=>{
    const { firstName, lastName, email, phoneNumber } = this.state;
    return !firstName || !lastName || !email || !phoneNumber;
  }

  disableSubmitButton=():boolean=>{
    const {organization, userType, city, intention, partnerDetails, address, why_you_want_join,cityError,userTypeError} = this.state
    return !organization|| !userType|| !city|| !intention||(intention === "collaboration_with_partner" && !partnerDetails) || !address || !why_you_want_join || !!cityError || !!userTypeError;
  } 

  clickNext=():void=>{
    this.setState({personalInformationSaved: true})
    
  }

  submitForm(form:{firstName:string, lastName:string, email:string, phoneNumber:string,organization:string, userType:string, city:string, intention:string, partnerDetails:string, address:string, why_you_want_join:string}){
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFormAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = {}
    if (form.intention === "collaboration_with_partner") {
      formData = {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        phone_number: form.phoneNumber,
        organization: form.organization,   
        i_am: form.userType,   
        independently: false,
        collaboration_with_partner: true,
        description: form.partnerDetails,
        city: form.city,                 
        address: form.address ,                
        why_you_want_join: form.why_you_want_join
      };
    } else {
      formData = {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        phone_number: form.phoneNumber,
        organization: form.organization,   
        i_am: form.userType,
        independently: true,
        collaboration_with_partner: false,
        city: form.city,                 
        address: form.address ,               
        why_you_want_join: form.why_you_want_join
      };
    }

    const httpBody = {
      form: formData,
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  handleOpenPopup = () => {
    this.setState({openPopup:true})
  };

  handleClosePopup:()=>void = () => {
    this.onCancel()
  };

  handleWordCountChange = (fieldName: keyof S, event: React.ChangeEvent<HTMLTextAreaElement>):void => {
    const count = event.target.value.length;
      const updatedState: Partial<S> = {
        [fieldName]: count,
      };
      this.setState(updatedState as S);
  };
submitFeedBack=()=>{
  const header = {
    "Content-Type": configJSON.forgotPasswordAPiContentType,
    token: localStorage.getItem("authToken"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.feedBackApiCallId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.feedbackFormEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  const httpBody = {
    feedback_form:{
      feedback_type:this.state.feedbackEmoji,
      feedback: this.state.feedbackText
   }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  openFeedBackPopup = ()=>{
    if(this.state.isLoggedIn){
      this.setState({isFeedBackFormOpen:true})
    }else{
      this.openSignInToContinuePopup()
    }
    
  }

  closeFeedBackPopup = ()=>{
    this.setState({isFeedBackFormOpen:false})
  }

  openFeedbackSubmittedPopup =()=>{
    this.setState({isformSubmittedPopupOpen:true})
  }
  closeFeedbackSubmittedPopup =()=>{
    this.setState({isformSubmittedPopupOpen:false})
  }
  handleSelectEmoji = (emoji:string)=>{
    this.setState({feedbackEmoji:emoji })
    
}
  handleFeedbackChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
     this.setState({feedbackText : event.target.value, charCount: event.target.value.length, errorToshow:""})
  }
  openSignInToContinuePopup=()=>{
    this.setState({isSignInToContinuePopupOpen:true})
  }
  closeSignInToContinuePopup=()=>{
    this.setState({isSignInToContinuePopupOpen:false})
  }
  toggleDropdown = () => {
    this.setState({isActive: !this.state.isActive});
  };
  toggleDropdown2 = () => {
    this.setState({isActive2: !this.state.isActive2});
  };

  closeDropdown=()=>{
    if(this.state.isActive === true){
    this.setState({isActive:false})
  } if(this.state.isActive2 === true){
    this.setState({isActive2: false})
  }
  }
  userType =():string[]=>["Business Owner","General Manager","Executive Chef","Financial Controller","Not Applicable"]
  cityToselect =():string[] => ["Winchester", "Hereford", "Havant"]
  checkErrorsForCity=()=>{
    if(!this.state.city){
      this.setState({cityError:"Please select a city"})
    } else{
      this.setState({cityError:""})
    }
  }
  checkErrorsForuserType=()=>{
    if(!this.state.userType){
      this.setState({userTypeError:"Please select your role"})
    } else{
      this.setState({userTypeError:""})
    }
  }
  backTopersonalDetails=()=>{
    this.setState({formSubmissionError:"",personalInformationSaved:false})
  }
  // Customizable Area End
}
