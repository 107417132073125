import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { enterFullscreen, exitFullscreen } from "../../../../../components/src/utils";
import { CartItem, CartItemAttributes, Order } from "../types";
import { debounce } from "lodash";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
};

export type Filters = {
  category: number | null;
  itemName: number | null;
  orderType: string | null;
};

interface S {
  token: string;
  selectedTab: number;
  ordersInCooking: Order[];
  ordersinQueue: Order[];
  currentPage: number;
  totalPages: number;
  openFilters: boolean;
  filters: Filters;
  isFullscreen: boolean;
  selectedItems: {
    order_number: string;
    items: Partial<CartItem>[];
  }[];
  search: string;
  categoryOptions: { id: number; title: string }[];
  itemNameOptions: { id: number; title: string }[];
};

interface SS {};

export default class ChefDashboardController extends BlockComponent<Props, S, SS> implements IBlock {
  orderListCallId: string = "";
  markReadyCallId: string = "";
  markAllReadyCallId: string = "";
  completeOrderCallId: string = "";
  subCategoryCallId: string = "";
  subSubCategoryCallId: string = "";
  elementRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getOrderListAfterDebounce = debounce(this.getOrderListAfterDebounce.bind(this), 300);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      selectedTab: 0,
      ordersInCooking: [],
      ordersinQueue: [],
      currentPage: 1,
      totalPages: 1,
      openFilters: false,
      filters: {
        category: null,
        itemName: null,
        orderType: null,
      },
      isFullscreen: false,
      selectedItems: [],
      search: "",
      categoryOptions: [],
      itemNameOptions: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  };

  async componentDidMount() {
    super.componentDidMount();

    await this.getToken();
    this.getOrderList();
    this.getSubCategory();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!response) return;

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler();
    if (error) runEngine.debugLog("API Error", error);
  }

  getMessageHandler(callId: string, response: any) {
    const messageHandlers = {
      [this.orderListCallId]: () => this.handleOrderList(response),
      [this.markReadyCallId]: () => this.getOrderList(),
      [this.markAllReadyCallId]: () => this.getOrderList(),
      [this.completeOrderCallId]: () => this.getOrderList(),
      [this.subCategoryCallId]: () => this.setState({ categoryOptions: response }),
      [this.subSubCategoryCallId]: () => this.setState({ itemNameOptions: response })
    };

    return messageHandlers[callId];
  };

  buildQueryParams = (filters: Filters) => {
    const orderType = filters.orderType ? `&order_type=${filters.orderType}` : "";
    const itemName = filters.itemName !== null
        ? `&sub_sub_categories=${this.state.itemNameOptions.find(val => val.id === filters.itemName)?.title || ""}`
        : "";
    const category = filters.category !== null
        ? `&sub_categories=${this.state.categoryOptions.find(val => val.id === filters.category)?.title || ""}`
        : "";
    const search = this.state.search ? `&search=${this.state.search}` : "";

    return `${orderType}${itemName}${category}${search}`;
  }

  getOrderList = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderListCallId = getDataMsg.messageId;
    const page = `?page=${this.state.currentPage}`;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chefDashboardAPI.endPoint + page + this.buildQueryParams(this.state.filters)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSubCategory = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subCategoryCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoryAPI
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSubSubCategory = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subSubCategoryCallId = getDataMsg.messageId;
    const category = this.state.filters.category ? `?sub_category_id=${this.state.filters.category}` : "";
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubSubCategoryAPI + category
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  markReady = (id: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markReadyCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chefDashboardAPI.endPoint + `/${id}` + configJSON.markReady
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  markAllReady = (id: number) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markAllReadyCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chefDashboardAPI.endPoint + configJSON.markAllReady
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = { order_id: id };
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  completeOrder = (id: number) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.completeOrderCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.chefDashboardAPI.endPoint + `/${id}` + configJSON.completeOrder
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  };

  handleOrderList = (response: any) => {
    this.setState({
      ordersInCooking: response?.orders_in_cooking ?? [],
      ordersinQueue: response?.orders_in_queue ?? [],
      currentPage: parseInt(response?.pagination?.current_page ?? "1") ?? 1,
      totalPages: response?.pagination?.total_pages ?? 1,
    });
  };

  handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  handlePreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 },
      this.getOrderList
    );
  };

  handleNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 },
      this.getOrderList
    );
  };

  handleOpenFilters = () => {
    this.setState({ openFilters: true });
  };

  handleCloseFilters = () => {
    this.setState({ openFilters: false });
  };

  handleCategoryChange = (event: SelectChangeEvent<number>) => {
    const filters = { ...this.state.filters, category: event.target.value as number, itemName: null };
    this.setState({ filters }, this.getSubSubCategory);
  };

  handleItemChange = (event: SelectChangeEvent<number>) => {
    const filters = { ...this.state.filters, itemName: event.target.value as number };
    this.setState({ filters });
  };

  handleOrderTypeChange = (checked: boolean | null, value: string) => {
    const filters = { ...this.state.filters, orderType: checked ? value : null };
    this.setState({ filters });
  };

  applyFilter = () => {
    this.setState({ currentPage: 1, openFilters: false },
      this.getOrderList
    );
  };

  handleEnterFullscreen = () => {
    if (this.elementRef.current) {
      enterFullscreen(this.elementRef.current);
      this.setState({
        isFullscreen: true
      });
    }
  };

  handleExitFullscreen = () => {
    exitFullscreen();
    this.setState({
      isFullscreen: false
    });
  };

  refreshOrders = () => {
    this.setState({
      filters: {
        category: null,
        itemName: null,
        orderType: null
      }
    }, this.getOrderList);
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ search: e.target.value }, this.getOrderListAfterDebounce);

  getOrderListAfterDebounce = this.getOrderList;

  renderAttributes = (attributes: CartItemAttributes) => {
    const attributeLabels: Partial<Record<keyof CartItemAttributes, string>> = {
      spice_level: "Spice Level",
      sides: "Sides",
      toppings: "Toppings",
      nibbles: "Nibbles",
      drinks: "Drinks",
      wraps_product: "Wraps Product",
    };

    return (Object.keys(attributeLabels) as (keyof CartItemAttributes)[])
    .filter((key) => {
      const value = attributes[key];
      return (
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : value !== "") // Exclude empty arrays or strings
      );
    })
    .map((key) => {
      const value = attributes[key];
      const displayValue = Array.isArray(value)
        ? value.map((item) => item.name).join(", ") // Extract and join `name` from objects
        : value; // Use the value directly for other types

      return (
        <div key={key}>
          <strong>{attributeLabels[key]}:</strong> {displayValue}
        </div>
      );
    });
  };
};
