import z from "zod";
import moment from "moment";

export const HolidaySchema = z.object({
  date: z
    .string()
    .nonempty({ message: "Date is required." }) // Ensure the string is not empty
    .refine((value) => {
      const date = moment.utc(value, moment.ISO_8601, true); // Parse as UTC
      return date.isValid() && date.isSameOrAfter(moment.utc(), "day");
    }, { message: "Date must not be before today or invalid." }),
  reason: z
    .string({
      required_error: "Reason cannot be empty.",
    })
    .min(1, { message: "Reason cannot be empty." })
    .max(20, { message: "Reason must be less than 20 characters." }),
});
