import React from "react";
import { IconButton } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { DeliveryArea } from "../../../../blocks/cfdeliveryareamanagement1/src/types";
import { FlagIcon, PencilIcon, TrashIcon } from "../../Icons";

const ActionCell = (
  info: CellContext<DeliveryArea, any>,
  handleFlagAreaPopupOpen: (address: DeliveryArea) => void,
  handleUpdateAddressPopup: (data: Partial<DeliveryArea>) => void,
  handleDeleteAreaPopupOpen: (id: number) => void,
  role: string
) => {
  const { row } = info;
  const { original } = row;
  const { id, flagged } = original;

  return (
    <div className="action_cell__wrapper">
      <IconButton
        onClick={() => handleUpdateAddressPopup(original)}
      >
        <PencilIcon />
      </IconButton>

      {role === "In Store Manager" && (
        <IconButton onClick={() => handleDeleteAreaPopupOpen(id)}>
          <TrashIcon />
        </IconButton>
      )}

      <IconButton
        color={flagged ? "error" : "default"}
        onClick={() => handleFlagAreaPopupOpen(original)}
      >
        <FlagIcon />
      </IconButton>
    </div>
  );
};

export default ActionCell;
