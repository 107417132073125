import React from "react";
import { Button, MenuItem, Select, SelectChangeEvent, SvgIcon, Tab, Tabs } from "@mui/material";
import { SearchRounded } from "@material-ui/icons";
import StaffInformationController, { Props } from "./StaffInformationController.web";
import DashboardWrapper from "../../../../../components/src/Dashboard/DashboardWrapper";
import UpdateStaffInfo from "../../../../../components/src/Dashboard/UpdateStaffInfo";
import AddNewStaff from "../../../../../components/src/Dashboard/AddNewStaff";
import Header from "../../../../../components/src/Dashboard/Header/Header";
import { BaseInput } from "../../../../../components/src/Inputs/CustomInputs";
import ConfirmationPopup from "../../../../../components/src/Dashboard/ConfirmationPopup";
import ManageNote from "../../../../../components/src/Dashboard/ManageNote";
import NoteSuccess from "../../../../../components/src/Dashboard/ManageNote/NoteSuccess";
import CreateStaffSuccess from "../../../../../components/src/Dashboard/StaffInformation/CreateStaffSuccess";
import StaffList from "../../../../../components/src/Dashboard/StaffInformation/StaffList";
import StaffSchedule from "../../../../../components/src/Dashboard/StaffInformation/StaffSchedule";
import AddShift from "../../../../../components/src/Dashboard/StaffInformation/AddShift";
import LoginLogoutModal from "../../../../../components/src/Dashboard/Scheduler/components/LoginLogoutModal";
import SuccessPopup from "../../../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import ErrorPopup from "../../../../../components/src/ErrorPopup";
import { lockedOut } from "../../assets";
import "./StaffInformation.web.css";

export default class StaffInformation extends StaffInformationController {
  searchRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
  }

  handleFocus = () => {
    this.searchRef.current?.focus();
  }

  searchStaff = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
        onClick={this.handleFocus}
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search Staff"
          value={this.state.search}
          onChange={this.handleSearchInput}
        />
      </div>
    );
  };

  render() {
    const { selectedTab, role } = this.state;

    return (
      <DashboardWrapper navigation={this.props.navigation}>
        <Header
          navigation={this.props.navigation}
          headerTitle="Staff Information"
          search={this.searchStaff()}
        />
        {role !== "In Store Manager"
          ? this.renderUnauthorizedAccess()
          : this.renderAuthorizedContent(selectedTab)}
      </DashboardWrapper>
    );
  }

  renderUnauthorizedAccess() {
    return (
      <div
        data-testid="staff-info-unauthorised"
        className="staff_info__unauthorised__wrapper"
      >
        <img
          src={lockedOut}
          alt="unauthorised"
          className="staff_info__unauthorised__image"
        />
        <span className="staff_info__unauthorised__text">
          You don&apos;t have permission to access this feature. Reach out to admin for further assistance.
        </span>
      </div>
    );
  }

  renderAuthorizedContent(selectedTab: number) {
    return (
      <div data-testid="staff-info" className="staff_info__wrapper">
        {this.renderTopBar(selectedTab)}
        {this.renderContent(selectedTab)}
        {this.renderModals()}
      </div>
    );
  }

  renderTopBar(selectedTab: number) {
    return (
      <div data-testid="top-bar" className="staff_info__topbar">
        <Tabs
          value={selectedTab}
          onChange={this.handleTabChange}
          classes={{
            root: "staffInformation__tab__wrapper",
            indicator: "staffInformation__tab__hidden_indicator",
          }}
        >
          <Tab
            label="Staff List"
            classes={{
              root: "staffInformation__tab",
              selected: "staffInformation__tab__selected",
            }}
          />
          <Tab
            label="Staff Schedule"
            classes={{
              root: "staffInformation__tab",
              selected: "staffInformation__tab__selected",
            }}
          />
        </Tabs>
        {this.renderTopBarRight(selectedTab)}
      </div>
    );
  }

  renderTopBarRight(selectedTab: number) {
    return (
      <div className="staff_info__topbar__right">
        <Select
          disabled={selectedTab !== 0}
          data-testid="role-filter"
          labelId="roles"
          id="roles"
          MenuProps={{
            classes: {
              paper: "staff_info__select__menu",
              list: "staff_info__select__list",
            },
          }}
          value={this.state.roleFilter ?? ""}
          onChange={(e: SelectChangeEvent<number>) =>
            this.handleRoleFilterChange(e.target.value as number)
          }
          classes={{
            root: "staff_info__select",
            select: "staff_info__select__input",
          }}
        >
          <MenuItem
            data-testid="role-filter-item"
            value={0}
            classes={{
              root: "staff_info__select__item",
              selected: "staff_info__select__item selected",
            }}
          >
            All Roles
          </MenuItem>
          {this.state.availableRoles.map((role) => (
            <MenuItem
              data-testid="role-filter-item"
              key={role.id}
              value={role.id}
              classes={{
                root: "staff_info__select__item",
                selected: "staff_info__select__item selected",
              }}
            >
              {role.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          disabled={selectedTab !== 0}
          data-testid="add-new-staff"
          classes={{
            root: "staff_info__add__button",
            text: "staff_info__add__button__text",
            disabled: "staff_info__add__button__disabled",
          }}
          startIcon={this.renderAddIcon()}
          onClick={this.handleAddStaffOpen}
        >
          Add New
        </Button>
      </div>
    );
  }

  renderAddIcon() {
    return (
      <SvgIcon>
        <svg
          width={800}
          height={800}
          viewBox="0 0 24 24"
          data-name="Line Color"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12h14m-7-7v14"
            style={{
              fill: "none",
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
        </svg>
      </SvgIcon>
    );
  }

  renderContent(selectedTab: number) {
    return (
      <div className="staff_information__content">
        {selectedTab === 0 && this.renderStaffList()}
        {selectedTab === 1 && this.renderStaffSchedule()}
      </div>
    );
  }

  renderStaffList() {
    return (
      <StaffList
        data-testid="staff-list"
        data={this.state.data}
        totalCount={this.state.totalCount}
        prevPage={this.state.prevPage}
        nextPage={this.state.nextPage}
        totalPages={this.state.totalPages}
        currentPage={this.state.currentPage}
        handleViewDetails={this.handleViewDetails}
        handleSelectedRow={this.handleSelectedRow}
        handleManageNoteOpen={this.handleManageNoteOpen}
        handlePageChange={this.handlePageChange}
      />
    );
  }

  renderStaffSchedule() {
    return (
      <StaffSchedule
        data-testid="staff-schedule"
        staff={this.state.staff}
        events={this.state.events}
        updateEvents={this.updateEvents}
        getShift={this.getShift}
        openShiftPopup={this.handleAddShiftOpen}
        totalShiftHours={this.state.totalShiftHours}
        openLogin={this.handleLoginModalOpen}
      />
    );
  }

  renderModals() {
    return (
      <>
        {this.state.openAddStaff && this.renderAddNewStaffModal()}
        {this.state.openUpdateStaff && this.state.selectedRow && this.renderUpdateStaffModal()}
        {this.state.openConfirmation && this.renderConfirmationPopup()}
        {this.state.selectedId && this.renderManageNoteModal()}
        {this.state.openNoteSuccess && this.renderNoteSuccessModal()}
        {this.state.staffCreated && this.renderCreateStaffSuccessModal()}
        {this.state.openShiftPopup && this.renderAddShiftModal()}
        {this.state.openLogin && this.renderLoginLogoutModal()}
        {this.state.successPopup.open && this.renderSuccessPopup()}
        {this.state.errorPopup.open && this.renderErrorPopup()}
      </>
    );
  }

  renderAddNewStaffModal() {
    return (
      <AddNewStaff
        data-testid="add-new-staff-modal"
        open={this.state.openAddStaff}
        onClose={this.handleAddStaffClose}
        staffId={this.state.staffId}
        identityTypes={this.state.availableIdentityTypes}
        roles={this.state.availableRoles}
        addStaff={this.addStaff}
        errors={this.state.errors}
        validationErrors={this.state.validationErrors}
      />
    );
  }

  renderUpdateStaffModal() {
    return this.state.selectedRow && (
      <UpdateStaffInfo
        data-testid="update-staff-info-modal"
        open={this.state.openUpdateStaff}
        onClose={this.handleUpdateStaffClose}
        identityTypes={this.state.availableIdentityTypes}
        roles={this.state.availableRoles}
        onSubmit={this.updateStaff}
        errors={this.state.errors}
        validations={this.state.validationErrors}
        data={this.state.selectedRow}
        confirmClose={this.confirmClose}
      />
    );
  }

  renderConfirmationPopup() {
    return (
      <ConfirmationPopup
        data-testid="confirmation-popup"
        open={this.state.openConfirmation}
        onClose={this.discardChanges}
        goBack={this.handleConfirmationClose}
      />
    );
  }

  renderManageNoteModal() {
    return this.state.selectedId && (
      <ManageNote
        data-testid="manage-note"
        id={this.state.selectedId}
        open={this.state.openManageNote}
        onClose={this.handleManageNoteClose}
        submit={this.addNote}
        edit={null}
      />
    );
  }

  renderNoteSuccessModal() {
    return (
      <NoteSuccess
        data-testid="note-success"
        open={this.state.openNoteSuccess}
        onClose={this.handleNoteSuccessClose}
      />
    );
  }

  renderCreateStaffSuccessModal() {
    return (
      <CreateStaffSuccess
        data-testid="create-staff-succes-modal"
        open={this.state.staffCreated}
        onClose={this.handleStaffCreatedClose}
      />
    );
  }

  renderAddShiftModal() {
    return (
      <AddShift
        data-testid="add-shift-modal"
        update={this.state.selectedEvent || undefined}
        apiError={this.state.updateError}
        selectedId={this.state.selectedIdForShift || undefined}
        open={this.state.openShiftPopup}
        onClose={this.handleAddShiftClose}
        submit={this.state.selectedEvent ? this.updateShift : this.addShift}
      />
    );
  }

  renderLoginLogoutModal() {
    return (
      <LoginLogoutModal
        data-testid="login-logout-modal"
        open={this.state.openLogin}
        onClose={this.handleLoginModalClose}
        login={this.login}
        logout={this.logout}
        event={this.state.selectedEvent}
      />
    );
  }

  renderSuccessPopup() {
    return (
      <SuccessPopup
        data-testid="success-popup"
        open={this.state.successPopup.open}
        onClose={this.handleSuccessPopupClose}
        message={this.state.successPopup.message || ""}
      />
    );
  }

  renderErrorPopup() {
    return (
      <ErrorPopup
        data-testid="error-popup"
        open={this.state.errorPopup.open}
        onClose={this.handleErrorPopupClose}
        message={this.state.errorPopup.message || ""}
      />
    );
  }
}
