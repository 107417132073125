import React from "react";
import moment from "moment-timezone";
import { Event, EventProps, View } from "react-big-calendar";
import { Popover } from "@mui/material";
import { CloseRounded, EditRounded } from "@material-ui/icons";
import { getEventColor, getSessionStorage, parseDateTime } from "../../../../utils";
import { ChevronRight, InfoIcon } from "../../../../Icons";
import { UnstyledIconButton } from "../../../../IconButton";
import LoginLogoutModal from "../LoginLogoutModal";
import "./EventComponent.css";

interface EventComponentProps extends EventProps {
  selectedStaff: number | null;
  selectedRole: string | null;
  activeView: View;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  openLogin: (event: Event) => void;
}

const EventComponent = (props: EventComponentProps) => {
  const { selectedStaff, selectedRole, activeView, openShiftPopup, event, openLogin } = props;

  const settings = getSessionStorage("restaurant_detail");
  const time_format = settings?.time_format;
  const is24HourFormat = time_format === "24-hour";

  const { start, end, title, resource } = event;
  const startTime = moment(start).format(is24HourFormat ? "HH:mm" : "hh:mm A");
  const startDate = moment(start).format("yyyy-MM-DD");
  const endTime = moment(end).format(is24HourFormat ? "HH:mm" : "hh:mm A");
  const diffInMinutes = moment(end).diff(start, "minutes");
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const totalHours = `${hours} hours ${minutes} mins`;

  const isToday = moment(moment(start)).isSame(moment(), "day");;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const status = resource?.status;
  const before = moment(start).isBefore(moment(), "day");

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div
      className="calendar__event"
      data-view={activeView}
      data-status={status}
    >
      {selectedStaff ? (
        <>
          {before ? (
            <>
              <div className="calendar__event__status">
                <UnstyledIconButton onClick={handleOpen}>
                  <InfoIcon width={16} height={16} />
                </UnstyledIconButton>
                <p className="calendar__event__title">{status.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p>
              </div>
              {activeView !== "month" && (
                <button disabled={!isToday} className="calendar__event__login" onClick={() => openLogin(event)}>
                  {resource?.isLoggedIn && resource?.isLoggedOut ? (
                    <span>Logged</span>
                  ) : (
                    <>
                      <span>{resource?.isLoggedIn ? "Log Out" : "Log in"}</span>
                      <ChevronRight width={16} height={16} />
                    </>
                  )}
                </button>
              )}
            </>
          ) : (
            <>
              <div className="calendar__event__status">
                <UnstyledIconButton onClick={() => selectedRole && openShiftPopup(event.resource.id, selectedRole, event)}>
                  <EditRounded fontSize="small" />
                </UnstyledIconButton>
                <p className="calendar__event__timestamp" onClick={handleOpen}>
                  {startTime} - {endTime}
                </p>
              </div>
              {activeView !== "month" && (
                <button disabled={!isToday} className="calendar__event__login" onClick={() => openLogin(event)}>
                  {resource?.isLoggedIn && resource?.isLoggedOut ? (
                    <span>Logged</span>
                  ) : (
                    <>
                      <span>{resource?.isLoggedIn ? "Log Out" : "Log in"}</span>
                      <ChevronRight width={16} height={16} />
                    </>
                  )}
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {resource?.role && (
            <div
              className="calendar__event__role_marker"
              data-role={getEventColor(resource.role).role}
            />
          )}
          <p className="calendar__event__title">{title}</p>
        </>
      )}
      <Popover
        disableRestoreFocus
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: "event_info__wrapper"
        }}
      >
        <div className="event_info__header">
          <span>{startDate}</span>
          <CloseRounded fontSize="small" style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <div className="event_info__body">
          <div className="event_info__body__title">
            <span className="event_info__title">Scheduled Time</span>
            <span className="event_info__timestamp">{startTime} - {endTime}</span>
          </div>
          <div className="event_info__login_logout">
            {resource?.login && <span>Log in - {moment(resource.login).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</span>}
            {resource?.logout && <span>Log out - {moment(resource.logout).format(is24HourFormat ? "HH:mm" : "hh:mm A")}</span>}
          </div>
          <div className="event_info__total_hours">
            <span>Total Hours</span>
            <span>{totalHours}</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export { EventComponent };
