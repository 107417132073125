import React from "react";
// Customizable Area Start
import * as Yup from 'yup';

import { Button, ThemeProvider, createTheme } from "@material-ui/core";
import LogoutPopup from "../../settings2/src/LogoutPopup.web";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { Field, FieldProps, Form, Formik } from "formik";
import './Address.css'
import PhoneInput from "react-phone-input-2";
import Profile from "../../../components/src/Profile";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import Select from "react-select";

import AddAddressController, {
  Props,
  configJSON,
  AdressTypeData
} from "./AddAddressController";

export default class AddAddress extends AddAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  userSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required(),
        phoneNumber: Yup.string().matches(/^\+(?:\d ?){8,14}\d$/, 'The mobile number you entered is not in a valid format. Please enter a valid mobile number.'),
        address: Yup.string().required(),
        city: Yup.string().required(),
        postalCode: Yup.string().required(),
        address_type: Yup.string().required(),
        default_address: Yup.boolean().required()
    });
};

formikDom = () => {
  return ( <Formik
    initialValues={{
        address_type: this.state.address_type,
        name:  "",
        address: "",
        city:  "",
        phoneNumber:  "",
        postalCode:  "",
        default_address:  false
    }}
    validationSchema={this.userSchema}
    onSubmit={(values) => {
    }}
    data-test-id="formikform"
>
    {({  handleChange, values }) => (
        <Form id="Form1" style={{display:"flex", flexDirection:"column", gap:"32px",width:"fit-content"}} >
          <div style={{display:"flex", gap:"6px", flexDirection:"column"}}>
          <div style={{display:"flex", flexDirection:"column", gap:"8px", padding:`${this.props.orderId ? "0px 40px" : "0px 10px"}`}}>
            <div style={{fontFamily:"Barmeno", fontWeight:700, fontSize:"14px", color:"#313131"}}>Save Address as <span className="required">*</span></div>
            <div style={{display:"flex", flexDirection:"row", gap:"16px"}}>
            <label className={"Home"===this.state.address_type?"address_type_radio_selected":"address_type_radio"} style={{fontFamily:"Barmeno", fontWeight:700}} >
                 <input onChange={(event) => {
                  this.handleInputChange('address_type', event.target.value)
                      handleChange(event);
                  }} checked={"Home"===this.state.address_type}  type='radio' name='address_type' style={{display: 'none'}} value="Home" />
            Home
            </label>
            <label className={"Work"===this.state.address_type?"address_type_radio_selected":"address_type_radio"} style={{fontFamily:"Barmeno", fontWeight:700}} >
                 <input onChange={(event) => { this.handleInputChange('address_type', event.target.value);  handleChange(event)}}
                  checked={"Work"===this.state.address_type} type='radio' name='address_type' style={{display: 'none'}} value="Work" />
            Work
            </label>
            <label className={"Other"===this.state.address_type?"address_type_radio_selected":"address_type_radio"} style={{fontFamily:"Barmeno", fontWeight:700}} >
                 <input onChange={(event) => {
                      this.handleInputChange('address_type', event.target.value)
                      handleChange(event);
                  }} checked={"Other"===this.state.address_type} type='radio' name='address_type' style={{display: 'none'}} value="Other" />
            Other
            </label>
            </div>
          </div>
            <div className="form_grid_container" style={{padding: `${this.props.orderId ? "10px 40px" : "10px"}`}}>
            <div className="details_field__wrapper" id="name">
                <label
                    style={{ fontFamily: "Barmeno", fontSize:"14px", fontWeight:700, color:"#747474" }}
                    htmlFor="name"
                >
                    Name
                </label>
                <Field
                    style={{fontSize:"16px",fontFamily: "Barmeno", width:"350px" }}
                    className={ "contact_form-field"
                    }
                    id="name"
                    name="name"
                    placeholder="Enter your first name"
                    onChange={(event: any) => {
                      this.handleInputChange('name', event.target.value)
                        handleChange(event);
                    }}
                    value={this.state.name}
                />
            </div>
            <div className="details_field__wrapper" id="phone" style={{width:"100%", maxWidth:"350px"}}>
                <label
                    style={{ fontFamily: "Barmeno", fontSize:"14px", fontWeight:700, color:"#747474" }}
                    htmlFor="phoneNumber"
                >
                    Mobile Number
                </label>
                <Field
                    style={{fontSize:"16px", fontFamily: "Barmeno", width:"350px" }}
                    className={"contact_form-field"
                    }
                    name="phoneNumber"
                    type="number"
                    placeholder="00000000"
                >
                    {({ field, form }: FieldProps<string>) => (
                        <PhoneInput
                        inputStyle={{fontWeight:700,color: "#313131", fontFamily: "Barmeno",width:"350px",backgroundColor: "#FFFFFF", border: "1px solid #313131 "}}
                            inputProps={{
                                name: "phoneNumber",
                                required: true,
                                autoFocus: false,
                            }}
                            buttonStyle={{backgroundColor:"white", border:"1px solid #313131"}}
                            country={"gb"}
                            value={this.state.full_phone_number}
                            onBlur={this.errorPhoneNumber}
                            onChange={(value) => {
                                const formattedValue = "+" + value;
                                this.handleInputChange('full_phone_number', formattedValue)
                                handleChange(formattedValue);
                                form.setFieldValue(
                                    "phoneNumber",
                                    formattedValue
                                );
                                form.setFieldTouched("phoneNumber", true);
                            }}
                        />
                    )}
                </Field>
                <div style={{color:"#E11B23",fontSize:"12px", fontFamily:"barmeno"}}> {this.state.phoneNumberError}</div>
            </div>
            <div style={{ fontFamily: "Barmeno" }} className="details_field__wrapper" id="address">
                <label
                    style={{ fontFamily: "Barmeno", fontSize:"14px", fontWeight:700, color:"#747474" }}
                    htmlFor="address"
                >
                    Address
                </label>
                <Field
                    style={{fontSize:"16px",fontFamily: "Barmeno"}}
                    className={ "contact_form-field"
                    }
                    name="address"
                    placeholder="Enter your address"
                    onChange={(event:any) => {
                      this.handleInputChange('address', event.target.value)
                        handleChange(event);
                    }}
                    value={this.state.address}
                />
            </div>
            <div style={{ fontFamily: "Barmeno" }} className="details_field__wrapper" id="city" >
                <label
                    style={{ fontFamily: "Barmeno", fontSize:"14px", fontWeight:700, color:"#747474" }}
                    htmlFor="city"
                >
                    City
                </label>
                <Field
                    style={{fontSize:"16px", fontFamily: "Barmeno",width:"350px" }}
                    className={"contact_form-field"
                    }
                    name="city"
                    placeholder="City"
                    onChange={(event:any) => {
                      this.handleInputChange('city', event.target.value)
                        handleChange(event);
                    }}
                    value={this.state.city}
                />
            </div>
            <div style={{ fontFamily: "Barmeno" }} className="details_field__wrapper" id="postal_code" >
                <label
                    style={{ fontFamily: "Barmeno", fontSize:"14px", fontWeight:700, color:"#747474" }}
                    htmlFor="postalCode"
                >
                    Post Code
                </label>
                <Field
                    style={{fontSize:"16px", fontFamily: "Barmeno",width:"350px" }}
                    className={"contact_form-field"
                    }
                    name="postalCode"
                    placeholder="PostCode/ Zip"
                    onChange={(event: any) => {
                      this.handleInputChange('post_code', event.target.value)
                        handleChange(event);
                    }}
                    value={this.state.post_code}
                />
                <div style={{color:"#E11B23",fontSize:"12px",fontFamily:"barmeno"}}>{this.state.postCodeError}</div>
            </div>
            </div>
            {!this.props.orderId && (<div className="default_address_container">
              <input
                style={{ width: '20px', height: '20px' }}
                type="checkbox"
                id="default_address"
                checked={this.state.default_address}
                onChange={(event:any) => {
                  this.handleInputChange('default_address', !this.state.default_address);
                  handleChange(event)}
              }
              />
              <label htmlFor="defaultCheckbox">
              Set as Default Address
            </label>
            </div>)}
            </div>
            <div
                style={{
                    padding: "24px 0px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "30px",
                    justifyContent:"center"
                }}
            >
                <Button className="cancelButton" onClick={() => {this.navigateToPages("AddressManagement")}} id="submit" style={{ fontFamily: "Barmeno" }} >
                    Cancel
                </Button>
                <Button
                    className={ this.disableSubmitButton() 
                    ? "submitButtonDisabledDet" : "submitButtonDet"}
                    disabled={this.disableSubmitButton() }
                    type="submit"
                    id="submit"
                    style={{ fontFamily: "Barmeno" }}
                    onClick={()=>this.handleSubmit()}
                >
                  {this.state.editAddressMode ? "Update": "Save"}
                </Button>
            </div>
        </Form>
    )}
</Formik>)
}
  
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Profile navigateToPages={this.navigateToPage}>
          <div className="main-panel" style={{ display:"flex", flexDirection:"column", gap:"32px", width: "100%", padding: "24px 80px 24px 80px", margin:  "40px 0 100px 0"}}> 
          {!this.props.orderId && (<div className="detail-edit-header" style={{marginLeft: `${this.props.orderId ? "40px" : "0px"}`}}> 
            <Button  test-id="back" onClick={()=>{this.navigateToPages("AddressManagement")}} style={{height:"56px",border:"none", color:"#313131", padding:"0px 12px !important", textTransform:"none"}}><ArrowBackRoundedIcon style={{height:"34px", marginRight:"12px", fontSize:"32px"}}/></Button>
            {this.state.editAddressMode ? "Update Address": "Add New Address"}
            </div>)}
           {this.formikDom()}
          </div>
          </Profile>
          <LogoutPopup open={this.state.logoutPopup}/>
        </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
