import z from "zod";

export const DeliveryAreaSchema = z.object({
  postcode: z
  .string()
  .min(1, { message: "Postcode cannot be left empty" })
  .refine((val) => {
    // UK Postcode: Matches formats like "SW1A 1AA", "M1 1AE"
    const ukPostcodeRegex = /^[A-Za-z]{1,2}\d[A-Za-z\d]? \d[A-Za-z]{2}$/;
    // India Postcode (PIN Code): Exactly 6 digits
    const indiaPostcodeRegex = /^\d{6}$/;
    // Dubai Postcode (P.O. Box format): Just numeric, can be 5 digits or more
    const dubaiPostcodeRegex = /^\d+$/;
    return ukPostcodeRegex.test(val) || indiaPostcodeRegex.test(val) || dubaiPostcodeRegex.test(val);
  }, { message: "Invalid postcode format" }),
  area: z.string().min(1, { message: "This field cannot be left empty" }),
  district: z.string().min(1, { message: "This field cannot be left empty" }),
  sector: z.string().min(1, { message: "This field cannot be left empty" }),
  minimum_order: z
    .string()
    .min(1, { message: "This field cannot be left empty" })
    .refine(
      (val) => {
        return !isNaN(parseFloat(val));
      },
      { message: "This field must be a number" }
    ).refine((val) => {
        return parseFloat(val) > 0;
    }, { message: "This field must be greater than 0" }),
  delivery_charge: z
    .string()
    .min(1, { message: "This field cannot be left empty" })
    .refine(
      (val) => {
        return !isNaN(parseFloat(val));
      },
      { message: "This field must be a number" }
    ),
    flagged: z.boolean().optional(),
    flag_reason: z.string().nullable().optional()
}).refine(
  (data) => {
    // Check if `flagged` is true and `flag_reason` is empty or null
    if (data.flagged) {
      // If flagged is true, check that flag_reason is neither null nor empty
      if (!data.flag_reason || data.flag_reason.trim() === "") {
        return false; // Invalid if flag_reason is empty or null when flagged is true
      }
    }
    return true; // Valid otherwise
  },
  {
    message: "Flag reason cannot be empty when flagged is true",
    path: ["flag_reason"], // This will set the error path for `flag_reason`
  }
);;
