import React from "react";
import Modal from "../Modal";
import { ContainedButton } from "../Button";
import { errorIcon } from "../../../blocks/dashboard/src/assets";
import "./ErrorPopup.css";

interface Props {
  open: boolean;
  onClose: () => void;
  message?: string;
};

const ErrorPopup = ({
  open,
  onClose,
  message = "An error occurred. Please try again later."
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="error_popup__wrapper">
        <img src={errorIcon} alt="error" />
        <p>{message}</p>
        <ContainedButton onClick={onClose}>
          Close
        </ContainedButton>
      </div>
    </Modal>
  )
};

export default ErrorPopup;
