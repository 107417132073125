import React from "react";
import CustomFacebookLogInButton from "./../../../blocks/social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "./../../../blocks/social-media-account/src/CustomGoogleLogInButton";
import { appleImg } from './assets';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {decodeJwt} from './../utils';
import AppleLogin from 'react-apple-login';
import { GoogleOAuthProvider } from "@react-oauth/google";
import './SocialLogin.css'

interface Props {
    // Define props here
    loggedIn?: (loggeIn: boolean) => {},
    navigation: any,
    appleLoginApiCall:(email:string,id_token:string)=>void
  }

interface S {
  isNetConnected: boolean;
  userId: string;
  identityToken: string;
  email: string;
  firstName: string;
  lastName: string;
  }

interface SS {

}

class SocialLogin extends BlockComponent<Props,S,SS>{
  apiSocialLoginCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    if (apiRequestCallId === this.apiSocialLoginCallId) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        const roles = responseJson.data.attributes.user_roles;
        sessionStorage.setItem("roles", JSON.stringify(roles));
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.props.loggedIn && this.props.loggedIn(true);
        this.saveLoggedInUserData(responseJson);
        this.openLoginPage(roles);
      } else {
        //Check Error Response
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  }

  handleAppleResponse = (response: any) => {
    const { authorization } = response;
    if (authorization && authorization.id_token) {
      console.log('Identity Token:', response);
      const responseDecoded = decodeJwt(authorization.id_token)
      this.props.appleLoginApiCall(responseDecoded.email,authorization.id_token)
    } else {
      console.error('Authorization failed');
    }
  };


  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  responseGoogle(credential: any) {
    if(credential) {
      const token = credential;
      this.doEmailLoggingIn({ token });
    }
  }

  responseFacebook(response: any) {
    if (response.accessToken) {
      this.doEmailLoggingIn(response);
    } else {
      this.onFailure(response);
    }
  };

  onFailure(response: any) {
    console.log('failure', response)
  }

  handleError(error: string) {
    console.log(error);
  };

  doEmailLoggingIn(response: any): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: response.token,
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSocialLoginCallId = requestMessages.messageId;
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialLoginAPiEndPoint
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.socialLoginAPiMethod
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);

    return true;
  }

  openLoginPage(roles: string[]) {
    if (roles[0] === "Buyer") this.props.navigation.navigate("Home");
    else if (roles.length > 0) {
      this.props.navigation.navigate("Dashboard");
    }
    else this.props.navigation.navigate("Home");
  }

    render() {
        return (
            <div style={{ margin: '28px auto 0 auto', width:"100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "32px" }}>
                <GoogleOAuthProvider clientId={configJSON.clientID}>
                   <CustomGoogleLogInButton testID="googleLogin" style={{  width: "58px", height: "58px", backgroundColor: "rgba(49, 49, 49, 0)",fontSize:'14px', borderRadius: "8px", borderWidth: "1px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }} onError={this.handleError} onResponse={(response) => this.responseGoogle(response)} useOneTap />
                 </GoogleOAuthProvider>
                 <div>
                   <CustomFacebookLogInButton loginFacebookButtonText="" appId="226038434598690" callback={(response) => {this.responseFacebook(response)}} onPress={() => console.log("Logged in successfully")} testID="facebok" />
                 </div>
                 <AppleLogin
                    clientId="cafe.builder.TopsPizza3platforms2Final2"
                    redirectURI={`${location.origin}`}
                    scope="name email"
                    responseType="code id_token"
                    responseMode="form_post"
                    usePopup={true}
                    state="initial"
                    callback={this.handleAppleResponse}
                    render={renderProps => (
                      <div onClick={renderProps.onClick} style={{ border: "1px solid #313131", borderRadius: "8px", borderWidth: '1px', padding: "11px", cursor: "pointer" }}>
                      <img style={{width: "34px", height: "34px"}} src={appleImg} alt="apple_img" />
                    </div>
                    )}
                  />
            </div>
        );
    }
}

export default SocialLogin;
