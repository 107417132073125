import React from "react";
import moment from "moment";
import "moment-timezone";
import { Calendar, Event, momentLocalizer, View, ViewsProps } from 'react-big-calendar'
import Toolbar from "./components/Toolbar";
import { Header } from "./components/Month";
import { EventComponent } from "./components/EventComponent";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";
import { getSessionStorage } from "../../utils";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  error?: {
    message: string;
  };
  recievedView?: View;
  recievedViews?: ViewsProps<Event, object>;
  events: Event[];
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  openLogin: (event: Event) => void;
};

const WingosCalendar = React.memo(({ selectedStaff, selectedRole, error, events, recievedView, recievedViews, openShiftPopup, totalShiftHours, openLogin }: Props) => {
  const [view, setView] = React.useState<View>("month");
  const [views, setViews] = React.useState<ViewsProps<Event, object>>({ month: true });
  const shifts = React.useMemo<Event[]>(() => events.filter(event => event.resource?.role ? event.resource.role === selectedRole : event), [events]);

  React.useEffect(() => {
    if (recievedView) {
      setView(recievedView);
    }
  }, [recievedView]);

  React.useEffect(() => {
    if (recievedViews) {
      setViews(recievedViews);
    }
  }, [recievedViews]);

  return (
    <Calendar
      defaultView="month"
      view={view}
      views={views}
      onView={(view: View) => setView(view)}
      events={shifts}
      localizer={localizer}
      className="calendar__view"
      components={{
        toolbar: (props) => <Toolbar
          {...props}
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          openAddShift={openShiftPopup}
          error={error}
          totalShiftHours={totalShiftHours}
        />,
        month: { header: Header },
        week: { header: Header },
        day: { header: Header },
        timeSlotWrapper: (props) => <div className="calendar__time_slot_wrapper">{props.children}</div>,
        resourceHeader: (props) => <div>{props.children}</div>,
        event: (props) => <EventComponent {...props} key={props.event.resource.id} selectedStaff={selectedStaff} selectedRole={selectedRole} activeView={view} openShiftPopup={openShiftPopup} openLogin={openLogin} />,
        eventWrapper: (props) => <div>{props.children}</div>,
        eventContainerWrapper: (props) => <div>{props.children}</div>
      }}
    />
  );
});

const settings = getSessionStorage("restaurant_settings");
const time_zone = settings?.time_zone;

moment.tz.setDefault(time_zone);

const localizer = momentLocalizer(moment)

export default WingosCalendar;
