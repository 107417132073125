import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import PaymentPopup from "../PaymentPopup.web";
import { Box, Dialog, DialogContent, Typography } from "@material-ui/core";
import { failure, success } from "../SocialLogin/assets";
import { customLoaderGif } from "../assets";



interface Props {
  id: any;
  navigation: any
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  heading: string;
  content: string;
  image: string;
};

export const configJSON = require("./config");

class PaymentStatus extends BlockComponent<
  Props,
  S,
  SS
> {


  getPaymentItentStatusCallId:string=""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      heading: "",
      content: "",
      image: "",
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const params = new URLSearchParams(location.search)
    const token = params.get('token');
    const paymentIntent = params.get('payment_intent');
    this.getPaymentIntentStatus(`${paymentIntent}`, `${token}`)
    // Customizable Area End
  }

  componentDidUpdate = async (prevProps: Props, prevState: S) => {

  }

  getPaymentIntentStatus = (payment_intent: string, token: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentItentStatusCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentIntentStatusEndPoint}${payment_intent}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if(apiRequestCallId === this.getPaymentItentStatusCallId){
        if(responseJson?.meta?.payment?.status === "succeeded"){
          this.setState({content: "Your payment verification confirmed!", heading:"Payment Success!", image:success})
        }else if(responseJson?.payment?.status === "succeeded") {
          this.setState({content: "Your payment verification confirmed!", heading:"Payment Success!", image:success})
        }
        else {
          this.setState({content: "Your payment verification failed!", heading:"Payment Failed!", image:failure})
        }
      }

    }
  }

  render() {

    return (
      <div style={{display:"flex",alignItems:"center", alignContent:"center", justifyContent:"center"}}>
      {this.state.image?
        <Box textAlign="center" style={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: "40px", backgroundColor: "white", maxWidth: "600px",width:"100%", padding: "16px", borderRadius: "8px", boxSizing: "border-box", margin: "20px auto 20px auto" }}>
          <img src={this.state.image} alt="status_image" style={{ width: "250px", height: "250px" }} />
          <div style={{ display: 'flex', flexDirection: "column", gap: '24px' }}><Typography style={{ fontFamily: 'Barmeno', fontSize: '24px', fontWeight: 700, textAlign: "center" }}>
           {this.state.heading}
          </Typography>
            <Typography style={{ fontFamily: 'Barmeno', fontSize: '18px', fontWeight: 500, textAlign: "center" }}>
             {this.state.content}
            </Typography></div>

          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "32px" }}>
          </div>
        </Box>
        :
        <img src={customLoaderGif} alt="loading..." style={{ width: "250px", height: "250px" }} />
        }
      </div>
    )
  }
}

export default PaymentStatus;