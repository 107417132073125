import React from "react";

// Customizable Area Start
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
// Customizable Area End

import PrintController, { ItemDetailData, Props, configJSON } from "./PrintController";
import { billingAllergen, billingNotes, receiptLogo } from "./assets";

export default class Print extends PrintController {
  // Customizable Area Start
  print: any = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {printerList, selectedPrinter,responseData} = this.state
    return (
      <div style={{ fontFamily: "Consolas", padding: "40px", justifyContent:"center", display:"flex" }}>
        {responseData &&
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "634px", boxSizing: "border-box", backgroundColor: "#fff" }} ref={this.contentRef}>
          <div className="storeInformationContainerdiv" style={{ padding: "40px 40px 32px 40px", display: "flex", flexDirection: "column", gap: "12px", alignItems: "center" }}>
            <img src={receiptLogo} alt="reciept logo" />
            <div style={{ width: "263px", display: "flex", flexDirection: "column", gap: "16px", fontSize: "18px", fontWeight: 400, textAlign: "center" }}>
              <div>{responseData.attributes.restaurant.location}</div>
              <div >
                <div>Tel: {responseData.attributes.restaurant.contact}</div>
                <div>VAT  {responseData.attributes.restaurant.tax_reg_no}</div>
                <div>www.wingos.co.uk</div>
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
          <div style={{ padding: "16px 40px", display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", fontWeight: 700, fontSize: "32px", maxWidth: "320px", width: "100%" }}>
              <div>{responseData.id}</div>
              <div>{responseData.attributes.order_type}</div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "40px", width: "554px", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "12px" }}><div style={{ width: "150px" }}>Customer:</div> <div style={{ width: "167px" }}>{`${responseData.attributes.buyer_details.first_name}`+` ${responseData.attributes.buyer_details.last_name}`}</div></div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "12px" }}><div style={{ width: "150px" }}>Mobile Number:</div> <div style={{ width: "167px" }}>{responseData.attributes.buyer_details.full_phone_number}</div></div>
                {responseData.attributes.order_type === "collection"?
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "12px" }}><div style={{ width: "150px" }}>Pickup Time:</div> <div style={{ width: "167px" }}>{this.convertDateTime(responseData.attributes.placed_at,responseData.attributes.restaurant.estimated_collection_time)}</div></div>:
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "12px" }}><div style={{ width: "150px" }}>Address:</div> <div style={{ width: "167px" }}>{responseData.attributes.delivery_addresses[0]}</div></div>
                }
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", maxWidth: "210px", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>{this.convertDateOnly(responseData.attributes.placed_at)}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>{this.convertDateTime(responseData.attributes.placed_at)}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>#{responseData.attributes.order_number}</div>
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
          <div style={{ padding: "16px 40px 8px 40px", display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontWeight: 700 }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                  <div style={{ width: "30px" }}>Qty </div><div> Product</div></div><div>Total</div>
              </div>
              {this.state.itemDetailData.map((value: ItemDetailData) => 
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                    <div style={{ width: "30px", display: "flex", justifyContent: "end" }}>{value.attributes.quantity} </div>
                     <div style={{display:"flex", flexDirection:"column",gap:"8px"}}> {value.attributes.product_name}
                      <div style={{display:"flex", flexDirection:"column",gap:"6px"}}>
                      {value.attributes.choose_your_type && <div style={{fontSize:"14px", fontWeight:400}}>- {value.attributes.choose_your_type}</div>}
                      {value.attributes.spice_level && <div style={{fontSize:"14px", fontWeight:400}}>- {value.attributes.spice_level}</div>}
                        {this.createStringSides(value.attributes.sides,value.attributes.drinks,value.attributes.nibbles,value.attributes.toppings).map((value:string)=>
                          <div style={{fontSize:"14px", fontWeight:400}}>- {value}</div>
                        )}
                        {value.attributes.allergen_excluded && <div style={{fontSize:"20px", fontWeight:700, display:"flex", flexDirection:"row",gap:"4px", alignItems:"center" }}>
                          <img src={billingAllergen} alt="allergen" style={{width:"24px",height:"24px"}} /> {value.attributes.allergen_excluded}</div>}
                      </div>
                     </div>
                  </div>
                  <div>£{value.attributes.price}</div>
                </div>)}
            </div>
            <div>
            </div>
              {responseData.attributes.notes_to_chef && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px", fontSize: "20px", fontWeight: 700 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}><img src={billingNotes} alt="notes" style={{ width: "24px", height: "24px" }} /> Notes: </div>
                <div>{responseData.attributes.notes_to_chef}</div>
              </div>}
          </div>
          <div>
          <div style={{padding:"24px 40px", display:"flex", flexDirection:"column", gap:"13px"}}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}><div>Subtotal</div> <div>£{responseData.attributes.sub_total}</div></div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}><div>Coupons/Discount</div> <div>£{responseData.attributes.applied_discount}</div></div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}><div>Redeem Points</div> <div>£{responseData.attributes.points_worth}</div></div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}><div>e-gift Card</div> <div>£{responseData.attributes.gift_card_amount}</div></div>
          </div>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", fontSize:"40px", fontWeight:700, padding:"0px 40px"}}><div>Total</div> <div>£{responseData.attributes.sub_total}</div></div>
          </div>
          <div style={{ borderTop: "1px dashed #000000", width: "100%" }}></div>
          <div  style={{boxSizing:"border-box",width:"100%",display:"flex", flexDirection:"column", gap:"16px", fontSize:"18px", fontWeight:400, padding:"40px 40px 32px 40px", alignItems:"center"}}>
            <div style={{display:"flex", flexDirection:"column", gap:"8px", alignItems:"center"}}>
              <div>Thank you for choosing Wingo’s </div>
              <div>Do visit again!</div>
            </div>
            <div>email: wingos.co.uk</div>
          </div>
        </div>
  }
        {selectedPrinter &&
          <button onClick={this.printHTML}>click</button>
        }

        <Dialog open={this.state.openPrinterPopup}>
          <DialogTitle>
            <Box display="flex" alignItems="center" justifyContent="center" style={{ fontFamily: "Barmeno", fontWeight: 700, fontSize: "24px" }}>
              Select the printer from the list
            </Box>
          </DialogTitle>
          <DialogContent>
            {printerList.length > 0 &&
              <Box display="flex" flexDirection="column" style={{ fontFamily: "Barmeno", fontWeight: 500, fontSize: "18px", paddingBottom: "20px", gap: "10px" }}>
                {printerList.map((value: any) =>
                  <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center", justifyContent: "space-between" }}>{value}
                    <button className="selectPrintButton" style={{ border: "0px", borderRadius: "8px", backgroundColor: "#E11B22", color: "white", height: "36px", width: "80px" }} onClick={() => { this.setSelectedPrinter(value) }}>Select</button></div>)}
              </Box>
            }
          </DialogContent>
        </Dialog>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
