Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.patchMethod = "PATCH";

exports.deliveryAreasAPI = {
  endPoint: "bx_block_cfdeliveryareamanagement1/delivery_addresses"
};
exports.postCodeSuggestion = {
    endPoint: "bx_block_location/locations?query=",
    type: "&type=postcode"
}
exports.googleApiKey = "AIzaSyDS3lSh1DVsIjUheUfsyk7UdUBKNKJkEVg"
// Customizable Area End
