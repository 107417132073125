import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import qz from "qz-tray"
import { createRef, RefObject } from "react";

export interface ItemDetailData {
  id: string;
  type: string;
  attributes: {
    id: number;
    catalogue_id: number;
    spice_level: string;
    choose_your_type: string;
    quantity: number;
    price: number;
    allergen_excluded: string;
    ready_for_delivery: boolean;
    product_name: string;
    sub_category: string;
    sides: any[];
    drinks: any[];
    nibbles: any[];
    toppings: any[];
    wrap_product: any[]
  }
}

export interface ResponseItems {
  id: string;
  type: string;
  attributes: {
    id: number,
    order_number: string;
    order_number_without_store_id: string;
    payment_source: string;
    placed_at: string;
    order_type: string;
    estimated_time: string;
    items_count: number;
    redeem_point: string;
    points_worth: string;
    gift_card_amount: string;
    applied_discount: string;
    sub_total: string;
    total: string;
    reward_points: null | string;
    notes_to_chef: null | string;
    delivery_addresses: any[];
    items_detail: {
      data: ItemDetailData[];
    };
    restaurant: RestaurantDetails;
    buyer_details: BuyerDetails
  }
}

export interface RestaurantDetails {
  id: number,
  title: string;
  location: string;
  contact: string;
  open_time: string;
  close_time: string;
  postcode: string;
  created_at: string;
  updated_at: string;
  order_type: string[];
  tax_reg_no: string;
  estimated_delivery_time: number;
  estimated_collection_time: number;
  latitude: string;
  longitude: string;
  radius: number;
  time_zone: string;
  time_format: string;
}

export interface BuyerDetails {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  order_id: number,
  created_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  connected: boolean;
  printerList: string[];
  selectedPrinter: string;
  openPrinterPopup: boolean;
  certificationKeys: string;
  itemDetailData: ItemDetailData[];
  responseData: ResponseItems | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrintController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  qzCreatedConnection: any
  contentRef: RefObject<HTMLDivElement>
  getKeysApiCallId: string = ""
  getDataFOrPrintingApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.contentRef = createRef<HTMLDivElement>();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      connected: false,
      printerList: [],
      selectedPrinter: "",
      openPrinterPopup: false,
      certificationKeys: "",
      itemDetailData: [],
      responseData:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getKeysApiCallId) {
        if (responseJson && responseJson.success) {
          this.setState({ certificationKeys: responseJson.certification_key })
          this.qzCreatedConnection = this.printSocketFunction(this.changeConnectionState, responseJson.certification_key)
        }
      }
      if (apiRequestCallId === this.getDataFOrPrintingApiCallId) {
        if (responseJson && responseJson.data) {
          this.setState({ itemDetailData: responseJson.data.attributes.items_detail.data, responseData:responseJson.data })
          this.getQZTrayKey()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getRecieptDataToPrint()
  }
  componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<S>) => {
    if (this.state.printerList !== prevState.printerList) {
      this.openPrinterDialogue()
    }
  }

  changeConnectionState = (connected: boolean) => {
    this.setState({ connected })
  }

  printSocketFunction = (changeConnectionState: (value: boolean) => void, certification_key: string) => {
    qz.security.setCertificatePromise((resolve: any, reject: any) => {
      resolve(
        certification_key
      );
    });

    if (!qz.websocket.isActive()) {
      console.log("THRU HERE");
      qz.websocket
        .connect({
          host: ["localhost"],
          port: {
            secure: [8181],
            insecure: [8182]
          },
          usingSecure: true,
          retries: 0,
          keepAlive: 60,
          delay: 0
        })
        .then(() => {
          changeConnectionState(true);
          qz.printers
            .find()
            .then((found: any) => this.setPrinterList(found));
        })
        .catch((error: any) => console.log(error));
    } else {
      changeConnectionState(true);
      console.log("websocket connection", qz.websocket.getConnectionInfo());
    }

    return qz;
  }

  printHTML = () => {
    const config = this.qzCreatedConnection.configs.create(this.state.selectedPrinter);
    const htmlContent = this.contentRef.current?.innerHTML || "";
    const data: qz.PrintData[] = [
      {
        data: htmlContent,
        flavor: "plain",
        type: "raw",
        format: "command",
      }
    ];

    this.qzCreatedConnection.print(config, data)
      .then(() => console.log("Print job successful"))
      .catch((error: string) => console.error("Print job failed", error));
  };

  qzTrayCloseConnection = (qzTray: any | null) => {
    qzTray.websocket.disconnect();
  }
  setSelectedPrinter = (value: string) => {
    this.setState({ selectedPrinter: value })
    this.closePrinterDialogue()

  }
  setPrinterList = (value: string[]) => {
    this.setState({ printerList: value })
  }
  openPrinterDialogue = () => {
    if (this.state.printerList.length > 0) {
      this.setState({ openPrinterPopup: true })
    }
  }
  closePrinterDialogue = () => {
    this.setState({ openPrinterPopup: false })
  }
  getQZTrayKey = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getKeysApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getKeysApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecieptDataToPrint = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDataFOrPrintingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReceiptDataforPrintingEndPoint}995`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createStringSides = (sides: any[], drinks: any[], nibbles: any[], toppings: any[]) => {
    const items = [
      ...sides.map((value) => value.name),
      ...drinks.map((value) => value.name),
      ...nibbles.map((value) => value.name),
      ...toppings.map((value) => value.name)
    ];
    return items
  }

  convertDateTime = (dateString: string,waitTime?:number) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    date.setMinutes(date.getMinutes() + (waitTime ||0));

    const formattedTime = date.toLocaleTimeString('en-GB', options);
    return formattedTime 
  }

  convertDateOnly = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });


    return formattedDate;
  }

  // Customizable Area End
}
