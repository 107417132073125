import React from "react";
import Sidebar from "./Sidebar/Sidebar";
import { isStoreUser } from "../utils";
import "./DashboardWrapper.css";

interface Props extends React.PropsWithChildren<{}> {
  navigation: any;
  hideSidebar?: boolean;
}

const DashboardWrapper = ({ children, navigation, hideSidebar }: Props) => {
  React.useEffect(() => {
    isStoreUser(navigation);
  }, []);

  return (
    <div
      data-testid="dashboard-wrapper"
      className="dashboard__wrapper"
    >
      <aside
        data-testid="dashboard-sidebar"
        className="dashboard__sidebar"
        data-hide={!!hideSidebar}
      >
        <Sidebar navigation={navigation} />
      </aside>

      <main
        data-testid="dashboard-main"
        className="dashboard__main"
        data-hideSidebar={!!hideSidebar}
      >
        {children}
      </main>
    </div>
  );
};

export default DashboardWrapper;
