import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: [];
  isActive:boolean;
  categories:string;
  isActive2:boolean;
  status:string;
  isActive3:boolean;
  quantity:string|number;
  action:string;
  selectedItemName: string;
  selectedCategory: string;
  selectedQuantity: string;
  selectedStatus: string;
  addEditStockPopup:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

  // Customizable Area End

export default class StockManagementReportController extends BlockComponent<
  Props,
  S,
  SS


> {
  // Customizable Area Start
  getProductsApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      productData: [],
      // Customizable Area Start
      isActive:false,
      categories:"",
      isActive2:false,
      status:"",
      isActive3:false,
      quantity:"",
      action:"",
      selectedItemName:"",
      selectedCategory:"",
      selectedQuantity:"",
      selectedStatus:"",
      addEditStockPopup:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }


  toggleDropdown = (fieldName: keyof S) => {
    const updatedState: Partial<S> = {
      [fieldName]: !this.state[fieldName],
    };
    console.log(this.state.categories, this.state.quantity, this.state.status)

    if (fieldName === 'isActive') {
      updatedState.isActive2 = false;
      updatedState.isActive3 = false;
    } else if (fieldName === 'isActive2') {
      updatedState.isActive = false;
      updatedState.isActive3 = false;
    } else if (fieldName === 'isActive3') {
      updatedState.isActive = false;
      updatedState.isActive2 = false;
    }

    this.setState(updatedState as S);
  };

  categoriesToselect =():string[] => ["All Categories", "Combos", "Drinks", "Light Bites", "Nibbles", "Platters", "Salads", "Sides", "Sweets", "Wraps, Pitas & Burgers"]
  statusToselect =():string[] => ["In Stock", "Out of Stock"]
  quantitiesToselect =():string[] => ["0-50", "50-100", "100-150", "150-200", "200+"]
  
  handleInputChange = ( fieldName: keyof S,value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S)
    this.setState({isActive:false,isActive2:false,isActive3:false})
  }

  editAction=()=>{
    this.setState({action:"edited",addEditStockPopup:true})
  }

  addAction=()=>{
    this.setState({addEditStockPopup:true})
  }

  closeAction=()=>{
    this.setState({action:"",addEditStockPopup:false})
  }
  statusChange=(value:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({status:value.target.value})
  }
  // Customizable Area End
}
