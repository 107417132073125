import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { AddRounded, CloseRounded, SearchRounded } from "@material-ui/icons";
import { FormControl, FormHelperText, IconButton, Tab, Tabs } from "@mui/material";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import { BaseInput, WingoInput } from "../../../components/src/Inputs/CustomInputs";
import { ClockIcon, PrintIcon } from "../../../components/src/Icons";
import Select from "../../../components/src/Select";
import FormLabel from "../../../components/src/FormLabel";
import { ContainedButton, OutlinedButton } from "../../../components/src/Button";
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import { StoreHolidayColumns } from "../../../components/src/Dashboard/DataGrid/columns/StoreHolidaysColumns";
import ActionCell from "../../../components/src/Dashboard/Settings/ActionCell";
import DataGrid from "../../../components/src/Dashboard/DataGrid";
import ErrorPopup from "../../../components/src/ErrorPopup";
import Modal from "../../../components/src/Modal";
import DateInput from "../../../components/src/DateTimePicker";
import { detectTimeFormat, isoToTime } from "../../../components/src/utils";
import "./Cfpossettings1.web.css";
// Customizable Area End

import Cfpossettings1Controller, {
  Props,
  configJSON,
} from "./Cfpossettings1Controller";
import { deleteImage } from "./assets";
import moment from "moment-timezone";

export default class Cfpossettings1 extends Cfpossettings1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  searchRef = React.createRef<HTMLInputElement>();
  columns = StoreHolidayColumns({
    actionCell: (info) => ActionCell(info, this.confirmDeletePopupOpen)
  });

  handleFocus = () => {
    this.searchRef.current?.focus();
  };

  renderSearch = () => {
    return (
      <div
        data-testid="search"
        className="cf_settings__search"
        onClick={this.handleFocus}
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search here"
          value={this.state.search}
          onChange={this.handleSearchInput}
        />
      </div>
    );
  };

  renderTabs = () => {
    const { selectedTab } = this.state;
    const icons: Record<string, JSX.Element> = {
      "General Settings": <ClockIcon />,
      "Print Settings": <PrintIcon />
    };
    const tabs = configJSON.tabs.map((tab: { id: number; title: string; }) => ({
      ...tab,
      icon: icons[tab.title] ?? undefined
    }));

    return (
      <Tabs
        data-testid="tabs"
        variant="fullWidth"
        value={selectedTab}
        onChange={this.setSelectedTab}
        classes={{
          root: "cf_settings__tabs",
          indicator: "cf_settings__tab__indicator",
        }}
      >
        {tabs.map((tab: { id: number; title: string; icon?: JSX.Element }) => (
          <Tab
            icon={tab.icon}
            label={tab.title}
            classes={{
              root: "cf_settings__tab",
              selected: "cf_settings__tab__selected",
            }}
          />
        ))}
      </Tabs>
    );
  };

  addHolidayDialog = () => {
    const open = this.state.addHolidayModal.open;
    const data = this.state.addHolidayModal.data;

    return (
      <Modal maxWidth="md" open={open} onClose={this.handleCloseAddHolidayModal}>
        <div className="cf_settings__add_holiday__wrapper">
          <div className="cf_settings__add_holiday__header">
            <h4>Add Holiday</h4>
            <IconButton onClick={this.handleCloseAddHolidayModal}>
              <CloseRounded />
            </IconButton>
          </div>

          <div className="cf_settings__add_holiday__form">
            <FormControl required>
              <FormLabel>Date</FormLabel>
              <DateInput
                value={data?.date}
                placeholderText="YYYY-MM-DD"
                onChange={this.handleDateChange}
              />
              <FormHelperText error={!!this.state.createHolidayErrors.find(value => value.field === "date")}>
                {this.state.createHolidayErrors.find(value => value.field === "date")?.message}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel>Reason</FormLabel>
              <WingoInput
                multiline
                minRows={4}
                name="reason"
                placeholder="Please enter the reason for the holiday"
                value={data?.reason}
                onChange={this.handleInputChange}
                error={!!this.state.createHolidayErrors.find(value => value.field === "reason")}
              />
              <FormHelperText
                classes={{ root: "cf_settings__add_holiday__form__textarea_helper" }}
                error={!!this.state.createHolidayErrors.find(value => value.field === "reason") || (data?.reason?.length ?? 0) > 20}
              >
                <span>{this.state.createHolidayErrors.find(value => value.field === "reason")?.message}</span>
                <span>{data?.reason?.length}/20</span>
              </FormHelperText>
            </FormControl>
          </div>

          <div className="cf_settings__add_holiday__footer">
            <OutlinedButton onClick={this.handleCloseAddHolidayModal}>CANCEL</OutlinedButton>
            <ContainedButton onClick={this.addHoliday}>ADD</ContainedButton>
          </div>
        </div>
      </Modal>
    )
  };

  confirmDeleteDialog = () => {
    const open = this.state.confirmDeletePopup.open;
    return (
      <Modal maxWidth="sm" open={open} onClose={this.confirmDeletePopupClose}>
        <div className="cf_settings__confirm_delete__wrapper">
          <img src={deleteImage} alt="delete" />
          <h4>Delete the Store holiday</h4>
          <p>Are you sure you want to delete this holiday?</p>
          <div className="cf_settings__confirm_delete__button_group">
            <OutlinedButton onClick={this.confirmDeletePopupClose}>CLOSE</OutlinedButton>
            <ContainedButton onClick={this.deleteHoliday}>DELETE</ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardWrapper
        navigation={this.props.navigation}
      >
        <Header
          navigation={this.props.navigation}
          headerTitle={configJSON.title}
          search={this.renderSearch()}
        />

        <div className="cf_settings__wrapper">
          {this.renderTabs()}

          {this.state.selectedTab === 0 && (
            <>
              <div className="cf_settings__content">
                <h2>
                  {configJSON.tabs.find((tab: { id: number; title: string; }) => tab.id === this.state.selectedTab).title}
                </h2>

                <div className="cf_settings__hours__wrapper">
                  <div className="cf_settings__section">
                    <div className="cf_settings__section__header">
                      <h3>Store Hours</h3>
                    </div>

                    <div className="cf_settings__time_settings">
                      <div className="cf_settings__formats">
                        <FormControl>
                          <FormLabel>Time Zone</FormLabel>
                          <Select
                            options={this.timeZoneOptions}
                            selectProps={{
                              name: "time_zone",
                              value: this.state.time_zone,
                              onChange: this.handleChange
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Time Format</FormLabel>
                          <Select
                            options={this.timeFormatOptions}
                            selectProps={{
                              name: "time_format",
                              value: this.state.time_format,
                              onChange: this.handleChange
                            }}
                          />
                        </FormControl>
                      </div>

                      {this.weekdays.map(weekday => {
                        const item = this.state.storeHours?.[weekday as string];
                        const open = moment(item?.open, ["HH:mm"]).format(this.state.time_format === "24-hour" ? "HH:mm" : "hh:mm A");
                        const close = moment(item?.close, ["HH:mm"]).format(this.state.time_format === "24-hour" ? "HH:mm" : "hh:mm A");

                        return (
                          <div key={weekday} data-testid="store-hours" className="cf_settings__store_hours">
                            <FormLabel>{weekday}</FormLabel>

                            <div className="cf_settings__day_hours">
                              <FormControl>
                                <FormLabel>From</FormLabel>
                                <Select
                                  options={this.state.timeOptions}
                                  selectProps={{
                                    name: `${weekday}+open`,
                                    value: open ?? "",
                                    onChange: this.handleStoreHoursChange
                                  }}
                                />
                                <FormHelperText error>
                                  {this.state.saveSettingsError.find(value => value.path.includes(weekday) && value.path.includes("open"))?.message ?? " "}
                                </FormHelperText>
                              </FormControl>

                              <FormControl>
                                <FormLabel>To</FormLabel>
                                <Select
                                  options={this.state.timeOptions}
                                  selectProps={{
                                    name: `${weekday}+close`,
                                    value: close ?? "",
                                    onChange: this.handleStoreHoursChange
                                  }}
                                />
                                <FormHelperText error>
                                  {this.state.saveSettingsError.find(value => value.path.includes(weekday) && value.path.includes("close"))?.message ?? " "}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          </div>
                        )}
                      )}
                    </div>
                  </div>

                  <div className="cf_settings__section">
                    <div className="cf_settings__section__header">
                      <h3>Upcoming Holidays</h3>
                      <button onClick={this.handleOpenAddHolidayModal}>
                        <AddRounded />
                        <span>Add</span>
                      </button>
                    </div>

                    {this.state.holidays.length > 0 ? (
                      <DataGrid
                        data={this.state.holidays}
                        columns={this.columns}
                      />
                    ) : (
                      <span className="cf_settings__placeholder">{configJSON.noHolidaysMessage}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="cf_settings__button_group">
                <OutlinedButton onClick={this.getSettings}>RESET</OutlinedButton>
                <ContainedButton onClick={this.updateSettings}>SAVE CHANGES</ContainedButton>
              </div>
            </>
          )}
        </div>


        {this.state.successPopup.open && (
          <SuccessPopup
            data-testid="success-popup"
            open={this.state.successPopup.open}
            onClose={this.handleSuccessPopupClose}
            message={this.state.successPopup.message || ""}
          />
        )}

        {this.state.errorPopup.open && (
          <ErrorPopup
            data-testid="error-popup"
            open={this.state.errorPopup.open}
            onClose={this.handleErrorPopupClose}
            message={this.state.errorPopup.message}
          />
        )}

        {this.addHolidayDialog()}
        {this.confirmDeleteDialog()}
      </DashboardWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
