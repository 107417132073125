import React from "react";
import { TimerIcon } from "../../../Icons";
import "./Timer.css";

interface Props {
  start: string;
};

const Timer = ({ start }: Props) => {
  const [elapsedTime, setElapsedTime] = React.useState("");
  const [color, setColor] = React.useState("#2C6F37");

  React.useEffect(() => {
    const startTime = new Date(start).getTime();

    const updateTimer = () => {
      const now = Date.now();
      const diff = now - startTime;

      // Convert difference into hours, minutes, and seconds
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setElapsedTime(
        (days > 0 ? `${days} day${days > 1 ? "s" : ""}, ` : "") +
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      );

      // Update color based on elapsed time
      setColor(getElapsedTimeColor(start));
    };

    // Initial call and interval setup
    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [start]);

  const getElapsedTimeColor = (start: string) => {
    const startTime = new Date(start).getTime();
    const now = Date.now();
    const diffInMinutes = (now - startTime) / (1000 * 60);

    if (diffInMinutes >= 0 && diffInMinutes < 5) {
      return "#2C6F37"; // Green for 0 to 5 mins
    } else if (diffInMinutes >= 5 && diffInMinutes < 10) {
      return "#F59E0B"; // Yellow for 5 to 10 mins
    } else if (diffInMinutes >= 10) {
      return "#E11B22"; // Red for above 10 mins
    }
    return "#2C6F37"; // Default color
  };

  return (
    <div className="timer__wrapper" style={{ backgroundColor: color }}>
      <TimerIcon />
      <p className="timer__label">{elapsedTime}</p>
    </div>
  );
};

export default Timer;
