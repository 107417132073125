import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { DeliveryArea } from "../../../../../blocks/cfdeliveryareamanagement1/src/types";

interface Props {
  actionCell: (info: CellContext<DeliveryArea, any>) => JSX.Element;
}

export const deliveryAreasColumns = ({ actionCell }: Props) => {
  const columns: ColumnDef<DeliveryArea, any>[] = [
    {
      header: "S.No",
      accessorKey: "id",
      cell: (info) => {
        const index = info.row.index + 1;
        return index < 10 ? `0${index}` : index;
      },
    },
    {
      header: "Area",
      accessorKey: "area",
      cell: (info) => info.getValue(),
    },
    {
      header: "District",
      accessorKey: "district",
      cell: (info) => info.getValue(),
    },
    {
      header: "Section",
      accessorKey: "sector",
      cell: (info) => info.getValue(),
    },
    {
      header: "Minimum Order",
      accessorKey: "minimum_order",
      cell: (info) => `£${info.getValue()}`,
    },
    {
      header: "Delivery Charge",
      accessorKey: "delivery_charge",
      cell: (info) => `£${info.getValue()}`,
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (info) => actionCell(info),
    },
  ];

  return columns;
};
