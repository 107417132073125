import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {deal1} from "./assets";
import { SpiceLevelTypes } from "../../ordermanagement/src/types";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  token: string;
  favourites: any;
  isVisible: boolean;
  activeId: number;
  favouriteId: number;
  favouriteType: string;
  activeCreatedAt: string;
  activeUpdatedAt: string;
  title: string;
  content: string;
  value: any;
  timeoutId: any;
  allergenList: string[];
  spiceLevelList:SpiceLevelTypes[];
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FavouritesController extends BlockComponent<Props, S, SS> {
  value: any;
  editorState: any;
  favouritesApiCallId: any;
  deleteFavouritesApiCallId: any;
  addFavouritesApiCallId: any;
  richtext: any;
  editor: any;
  onChange: (editorState: any) => void;
  setEditor: (editor: any) => void;
  focusEditor: () => void;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.editorState = null; //createEditorStateWithText("");

    // Customizable Area Start
    this.deleteFavorite = this.deleteFavorite.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      favourites: [],
      isVisible: false,
      activeId: 0,
      favouriteId: 0,
      favouriteType: "",
      activeCreatedAt: "",
      activeUpdatedAt: "",
      title: "",
      content: "",
      value: this.value,
      timeoutId: null,
      allergenList: [],
      spiceLevelList:[],
      loading:false,
    };
    this.onChange = (value) => {
      this.value = value;
      this.setState({ value: value });
    };
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    try {
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        await this.handleSessionResponse(message);
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        await this.handleRestApiResponse(message);
      }
    } catch(error) {
      console.log("ERROR", error);
    }
   
    // Customizable Area End
  }

  // Customizable Area Start

  allergenListApiCallId: string="";
  getSpiceLevelApiCallId:string="";
  handleSessionResponse = async (message: Message) => {
    runEngine.debugLog("Message Recived", message);
  
    const token = localStorage.getItem("authToken") || "";
    this.setState({ token });
    this.getFavourites(token);
    this.getAllergenList();
  }

  handleRestApiResponse = async (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    runEngine.debugLog("API Message Recived", message);
  
    if (responseJson && responseJson.data) {
      this.handleApiResponseWithData(apiRequestCallId, responseJson);
    } else if (responseJson && !responseJson.data && apiRequestCallId === this.deleteFavouritesApiCallId) {
      this.handleApiResponseWithoutData();
    } else if (responseJson && responseJson.errors) {
      this.handleApiResponseErrors(apiRequestCallId, responseJson, errorReponse);
    }
  }

  handleApiResponseWithData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.favouritesApiCallId) {
      this.setState({ favourites: responseJson.data, loading:false });
    }
    if (apiRequestCallId === this.addFavouritesApiCallId) {
      this.props.navigation.goBack();
    }if (apiRequestCallId === this.allergenListApiCallId){
      const allergens = responseJson.data.map((allergenObject:{id: number; name: string; created_at: Date; updated_at:Date})=>
        allergenObject.name
      )
      this.setState({allergenList: allergens})
    } if(apiRequestCallId === this.getSpiceLevelApiCallId){
      this.setState({spiceLevelList:responseJson.data})
    }
  }

  
  handleApiResponseWithoutData = () => {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
      const newTimeoutId = setTimeout(() => {
        this.setState({ isVisible: false });
      }, 6000);
      this.setState({timeoutId: newTimeoutId, isVisible: true});
      this.getFavourites(this.state.token);
  }

  handleApiResponseErrors(apiRequestCallId: number, responseJson: any, errorReponse: any) {
    if (apiRequestCallId === this.addFavouritesApiCallId) {
      this.showAlert("Error", responseJson.errors);
    } else {
      this.handleFavouritesError(responseJson);
      Array.isArray(responseJson.errors) && this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
      
    }
  }

  handleFavouritesError = (responseJson: any) => {
    if(responseJson.errors && responseJson.errors === "Favourites not found") {
      this.setState({favourites: [],loading:false});
    }
  }

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      favouriteId: item.attributes.favouriteable_id,
      favouriteType: item.attributes.favouriteable_type,
      activeCreatedAt: item.attributes.created_at,
      activeUpdatedAt: item.attributes.updated_at,
      isVisible: !this.state.isVisible,
    });
  };
  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
      const targetElement = document.getElementById('root-element-container');
      if (targetElement) {
        targetElement.remove();
      }
  };

  addFavourites() {
    //@ts-ignore
    this.props.navigation.navigate("AddFavourites");
  }

  setType = (txt: string) => {
    this.setState({ favouriteType: txt });
  };

  setID = (txt: string) => {
    this.setState({ favouriteId: Number(txt) });
  };

  deleteFavorite(id: number) {
    this.deleteFavouritesApiCall(configJSON.favouritesApiEndPoint + `/${id}`);
  }

  addFavouritesCall = () => {
    if (this.state.favouriteType.trim() === "") {
      this.showAlert(configJSON.configError, configJSON.configErrorType);
      return false;
    } else if (this.state.favouriteId <= 0) {
      this.showAlert(configJSON.configError, configJSON.configErrorId);
      return false;
    } else {
      var data = {
        favouriteable_id: this.state.favouriteId,
        favouriteable_type: this.state.favouriteType,
      };

      const header = {
        "Content-Type": configJSON.favouritesApiApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addFavouritesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouritesApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: data })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteFavouritesApiCall = (endPointURL: string) => {
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFavourites = (token: string, filterUri: string = '') => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.favouritesApiEndPoint}${filterUri}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  } 

  getAllergenList=()=>{
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allergenListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allergensList);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToMenu = (id:string,order_type:string,title:string,catalogue_id:string) => {
  const message: Message = new Message(
    getName(MessageEnum.NavigationMenuMessage)
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(getName(MessageEnum.NavigationPayLoadMessage),{restaurant_id:id ,order_type:order_type,title:title,menu_item_id:catalogue_id})
  this.send(message);
}
getSpiceLevel=()=>{
  const header = {
    "Content-Type": configJSON.favouritesApiApiContentType
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getSpiceLevelApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getSpiceLevelApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

converSpiceLevel=()=>{
  this.setState({spiceLevelList:[]})
}
  // Customizable Area End
}
