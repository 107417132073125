import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {image_location, image_phone_in_talk, image_schedule} from "./assets";
import { RestaurantFactsAttr } from '../../blocks/shoppingcart/src/ShoppingCartOrdersController';
import { StoreHoursAttribute } from '../../blocks/storelocator/src/StoreLocatorController';
import { setStoreHours } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    },
    heading: {
      fontFamily: 'Barmeno, sans-serif',
      fontSize: '30px',
      fontWeight: 'bolder',
      lineHeight: '40px',
    },
    details: {
      flexDirection: 'column',
      gap: '12px'
    },
    entity: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center'
    },
    value: {
      fontFamily: 'Barmeno, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '16px',
      color: '#747474'
    }
  }),
);

interface Props{
  restaurantDetails:RestaurantFactsAttr
}

const SimpleAccordion:React.FC<Props> =({restaurantDetails}) =>{
  const classes = useStyles();

  const getOpenCloseData=(store_hours:StoreHoursAttribute[],openTime:string,closeTime:string)=>{
    const {open, close} = setStoreHours(store_hours)
    return(
      <Typography className={classes.value}>Open {open || openTime} to {close || closeTime} </Typography>
    )
  }

  return (
    <div>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Your Feast from : {restaurantDetails.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.entity}>
            <img src={image_location} alt='image_location' />
            <Typography className={classes.value}>{restaurantDetails.location}</Typography>
          </div>
          <div className={classes.entity}>
            <img src={image_phone_in_talk} alt='image_phone_in_talk' />
            <Typography className={classes.value}>{restaurantDetails.primary_phone 
    ? `${restaurantDetails.country_code} ${restaurantDetails.primary_phone}` +
      (restaurantDetails.secondary_phone ? `, ${restaurantDetails.secondary_phone}` : '')
    : restaurantDetails.contact}</Typography>
          </div>
          <div className={classes.entity}>
            <img src={image_schedule} alt='image_schedule' />
             {getOpenCloseData(restaurantDetails.store_hours,restaurantDetails.open_time, restaurantDetails.close_time)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SimpleAccordion