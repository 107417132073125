import React from "react";
import { IconButton } from "@mui/material";
import { Event, ToolbarProps } from "react-big-calendar";
import { AddRounded } from "@material-ui/icons";
import { CalendarOutlined, ChevronLeft, ChevronRight, WarningAmberOutlined } from "../../../../Icons";
import { UnStyledButton } from "../../../../Button";
import "./Toolbar.css";

interface Props extends ToolbarProps {
  selectedStaff: number | null;
  selectedRole: string | null;
  openAddShift: (id: number, selectedRole: string, event?: Event) => void;
  error?: {
    message: string;
  };
  totalShiftHours: string;
}

const Toolbar = ({ selectedStaff, selectedRole, openAddShift, error, totalShiftHours, ...props }: Props) => {
  const { label, onNavigate, views, view: activeView, onView } = props;

  const getViews = () => {
    if (typeof views === "object") {
      return Object.values(views);
    } else {
      return views;
    };
  };

  return (
    <>
      <div className="scheduler__header">
        <div className="scheduler__header__top">
          <div className="scheduler__header__left">
            <div className="scheduler__header__left__title">
              <span>{label}</span>
            </div>
            {!!totalShiftHours && (
              <p className="scheduler__header__left__total_hours">
                Total Hours: {totalShiftHours}
              </p>
            )}
            {error && (
              <div className="scheduler__header__left__error">
                <span>{error.message}</span>
                <WarningAmberOutlined />
              </div>
            )}
          </div>

          <div className="scheduler__header__right">
            <div className="scheduler__header__right__button_group">
              <IconButton onClick={() => onNavigate("PREV")}>
                <ChevronLeft />
              </IconButton>
              <IconButton onClick={() => onNavigate("NEXT")}>
                <ChevronRight />
              </IconButton>
            </div>

            <IconButton onClick={() => onNavigate("TODAY")}>
              <CalendarOutlined />
            </IconButton>

            <div className="scheduler__header__right__tab">
              {getViews().map((view) => (
                <div
                  key={view}
                  data-active={view === activeView}
                  className="scheduler_tab"
                  onClick={() => onView(view)}
                >
                  {view}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="scheduler__header__bottom">
          {selectedStaff && (
            <UnStyledButton
              startIcon={<AddRounded />}
              classes={{
                root: "scheduler__header__footer__button",
                text: "scheduler__header__footer__button__text",
              }}
              onClick={() => selectedRole && openAddShift(selectedStaff, selectedRole)}
            >
              Add Shift
            </UnStyledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default Toolbar;
