import { z } from "zod";
import { getTodayDate } from "../utils";

export const documentValidation = z.array(z.object({})).min(1, { message: "At least one document is required" });

export const StaffGeneralInfoSchema = z.object({
  roles: z.array(z.number()).min(1, { message: "Roles are required" }),
  firstName: z.string().min(1, { message: "This field should not be left empty" }).max(30, { message: "First name must be at most 30 characters" }),
  lastName: z.string().min(1, { message: "This field should not be left empty" }).max(30, { message: "Last name must be at most 30 characters" }),
  phoneNumber: z
    .string()
    .min(10, { message: "The mobile number you entered is not in a valid format. Please enter a valid mobile number" })
    .min(1, { message: "Phone number is required" }),
  identityType: z.string().min(1, { message: "Identity type is required" }),
  identityDocument: documentValidation,
  postCode: z
    .string()
    .min(6, { message: "Postcode must be at least 6 characters" })
    .min(1, { message: "Postcode is required" }),
  permitRequired: z.boolean(),
});

export const StaffAccountInfoSchema = z
  .object({
    email: z
      .string()
      .email({ message: "The email address you entered is not in a valid format. Please enter a valid email address" })
      .min(1, { message: "Email is required" }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" })
      .min(1, { message: "Password is required" })
      .refine((value) => /[a-z]/.test(value) && /[A-Z]/.test(value), {
        message: "Password must contain both uppercase and lowercase characters",
        path: ["password"],
      })
      .refine((value) => /\d/.test(value), {
        message: "Password must contain numbers",
        path: ["password"],
      })
      .refine((value) => /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]+$/.test(value), {
        message: "Password must contain alphanumeric and special characters",
        path: ["password"],
      }),
    confirmPassword: z.string().min(1, { message: "Confirm password is required" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Password confirmation does not match. Please try again!",
    path: ["confirmPassword"],
  });

export const PermitSchema = z.object({
  visaDocument: documentValidation,
  expiryDate: z.string().refine((date) => {
    if (!date) return false;

    return new Date(date) > new Date(getTodayDate());
  }, {
    message: "Expiry date must be in the future",
  }),
});
