
import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Holiday } from "../../Settings/types";

interface Props {
  actionCell: (info: CellContext<Holiday , any>) => JSX.Element;
};

export const StoreHolidayColumns = ({ actionCell }: Props) => {
    const columns: ColumnDef<Holiday, any>[] = [
        {
            header: "Date",
            accessorKey: "date",
            cell: (info) => info.getValue(),
            enableSorting: true
        },
        {
            header: "Reason",
            accessorKey: "reason",
            cell: (info) => info.getValue(),
        },
        {
        header: "Action",
        accessorKey: "action",
        cell: (info) => actionCell(info),
        },
    ];

    return columns;
};
