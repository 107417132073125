import React from "react";
import { SelectProps, Select as MuiSelect, MenuItem } from "@mui/material";
import "./Select.css";

type Option = string | { value: string | number; label: string; };

interface Props {
    selectProps?: SelectProps;
    options: Option[];
}

const Select = (props: Props) => {
    return (
        <MuiSelect
            {...props.selectProps}
            MenuProps={{
                classes: { paper: "select__menu", list: "select__list" },
            }}
            classes={{
                root: "select",
                select: "select__input",
            }}
        >
            {(props.options as Option[]).map((option) => {
                let value: string | number;
                let label: string;

                if (typeof option === "string" || typeof option === "number") {
                    value = option;
                    label = option.toString();
                } else {
                    value = option.value;
                    label = option.label;
                }

                return (
                    <MenuItem
                        data-testid="role-filter-item"
                        value={value}
                        classes={{ root: "select__item", selected: "select__item selected" }}
                    >
                        {label}
                    </MenuItem>
                )
            })}
        </MuiSelect>
    );
};

export default Select;
