import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import moment from "moment-timezone";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  role: string;
  userDetail: any;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserDetailsApiCallId: string = "";
  getRestaurantDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      role: "",
      userDetail: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    await this.getToken();

    this.getUserDetails();
    this.getRestaurantDetails();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let failedApiRequest = message.id === getName(MessageEnum.RestAPIResponceErrorMessage);
    let isUserDetailsApiCall = apiRequestCallId === this.getUserDetailsApiCallId;
    let isRestaurantDetailsApiCall = apiRequestCallId === this.getRestaurantDetailsCallId;

    if (!responseJson) return;
    if (responseJson?.errors?.[0]?.token) {
      this.showAlert("token", "token");
      return;
    }

    if (isUserDetailsApiCall) {
      const userDetail = responseJson;

      this.setState({
        userDetail: userDetail
      });
    }

    if (isRestaurantDetailsApiCall) {
      sessionStorage.setItem("restaurant_settings", JSON.stringify(responseJson));
      responseJson?.time_zone && moment.tz.setDefault(responseJson?.time_zone);
    }

    if (failedApiRequest) {
      return;
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = async () => {
    const token = localStorage.getItem("authToken");

    if (token) this.setState({ token: token });
    else this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  getUserDetails = () => {
    const header = {
      "Content-Type": webConfigJSON.userDetails.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.userDetails.endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.userDetails.method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRestaurantDetails = () => {
    const header = {
      "Content-Type": webConfigJSON.restaurantDetails.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRestaurantDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.restaurantDetails.endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.restaurantDetails.method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
