import React from "react";
import Modal from "../../../Modal";
import { ContainedButton, OutlinedButton } from "../../../Button";
import { deleteAddressImage } from "../../../../../blocks/cfdeliveryareamanagement1/src/assets";
import "./DeleteAddressPopup.css";

interface Props {
    open: boolean;
    onClose: () => void;
    submit: () => void;
};

const DeleteAddressPopup = ({
    open, onClose, submit
}: Props) => {
    return (
        <Modal
            closeButton
            open={open}
            onClose={onClose}
            maxWidth="sm"
        >
            <div className="delete_address__wrapper">
                <img src={deleteAddressImage} alt="delete address popup" />

                <div className="delete_address__body">
                    <h3>Delete the Delivery Area</h3>
                    <p>Are you sure want to delete this delivery area?</p>
                </div>

                <div className="delete_address__button_group">
                    <OutlinedButton onClick={onClose}>CANCEL</OutlinedButton>
                    <ContainedButton onClick={submit}>DELETE</ContainedButton>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteAddressPopup;
