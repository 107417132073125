import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Event, View } from "react-big-calendar";
import moment from "moment-timezone";
import { IconButton } from "@mui/material";
import { ChevronRight } from "../../../../../components/src/Icons";

interface Props {
    onView?: (view: View) => void;
};

const ScheduleListColumns = ({ onView }: Props) => {
    const columns: ColumnDef<Event, any>[] = [
        {
            header: "Day",
            accessorKey: "day",
        },
        {
            header: () => <div>Scheduled Time<br />(Log in)</div>,
            accessorKey: "start",
        },
        {
            header: () => <div>Scheduled Time<br />(Log off)</div>,
            accessorKey: "end",
        },
        {
            header: () => <div>User<br/ >Log in</div>,
            accessorKey: "login",
        },
        {
            header: () => <div>User<br/ >Log off</div>,
            accessorKey: "logout",
        },
        {
            header: "Action",
            accessorKey: "action",
            cell: () => (
                <div className="action_cell__wrapper">
                    <IconButton onClick={() => onView && onView("week")}>
                        <ChevronRight />
                    </IconButton>
                </div>
            ),
        },
    ];

    return columns;
};

export default ScheduleListColumns;
