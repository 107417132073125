Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.patchMethod = "PATCH";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.settingsEndPoint = "bx_block_cfpossettings1/restaurant_settings/get_setting";
exports.updateSettingsEndPoint = "bx_block_cfpossettings1/restaurant_settings";
exports.getHolidaysEndPoint = "bx_block_cfpossettings1/restaurant_settings/get_holiday_list";
exports.addHolidayEndPoint = "bx_block_cfpossettings1/restaurant_settings/create_holiday";
exports.deleteHoldayEndPoint = "bx_block_cfpossettings1/restaurant_settings/holidays";

exports.title = "Settings";
exports.tabs = [
  { id: 0, title: "General Settings" },
  { id: 1, title: "Print Settings" }
];
exports.noHolidaysMessage = "No Upcoming Holidays";
// Customizable Area End
