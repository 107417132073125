import React from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { OrderStatistics as Data } from "../../../../blocks/dashboard/src/dashboards/types";

interface Props {
  orderStatistics: Data;
}

const OrderStatistics = ({ orderStatistics }: Props) => {
  return (
    <ResponsiveContainer>
      <AreaChart
        data={orderStatistics.daily_completed_orders}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        title="Order Statistics"
      >
        <defs>
          <linearGradient
            id="colorOrderCount"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor="#8884d8"
              stopOpacity={0.8}
            />
            <stop
              offset="100%"
              stopColor="#8884d8"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip
          labelStyle={{
            color: "#1D1D1D",
            fontSize: "14px",
            fontFamily: "Barmeno",
            fontWeight: "bold",
          }}
          contentStyle={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
          itemStyle={{
            color: "#1D1D1D",
            fontSize: "14px",
            fontFamily: "Barmeno",
          }}
          formatter={(value: any) => [value, "Orders"]}
        />
        <Area
          type="monotone"
          dataKey="order_count"
          strokeWidth={1.5}
          stroke="#2C6F37"
          fill="url(#colorOrderCount)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default OrderStatistics;
