import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { AddRounded } from "@material-ui/icons";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import Loader from "../../../components/src/Loader.web";
import DataGrid from "../../../components/src/Dashboard/DataGrid";
import FlagAreaPopup from "../../../components/src/Dashboard/DeliveryAreas/FlagAreaPopup";
import ErrorPopup from "../../../components/src/ErrorPopup";
import AddAddressPopup from "../../../components/src/Dashboard/DeliveryAreas/AddAddressPopup";
import UpdateAddressPopup from "../../../components/src/Dashboard/DeliveryAreas/UpdateAddressPopup";
import ActionCell from "../../../components/src/Dashboard/DeliveryAreas/ActionCell";
import { ContainedButton } from "../../../components/src/Button";
import { deliveryAreasColumns } from "../../../components/src/Dashboard/DataGrid/columns/DeliveryAreasColumns";
import DeleteAddressPopup from "../../../components/src/Dashboard/DeliveryAreas/DeleteAddressPopup";
// Customizable Area End

import Cfdeliveryareamanagement1Controller, {
  Props,
  configJSON,
} from "./Cfdeliveryareamanagement1Controller";
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";

export default class Cfdeliveryareamanagement1 extends Cfdeliveryareamanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    columns = deliveryAreasColumns({
        actionCell: (info) => ActionCell(
          info,
          this.handleFlagAreaPopupOpen,
          this.handleUpdateAddressPopupOpen,
          this.handleDeleteAreaPopupOpen,
          this.state.role
        )
    });
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardWrapper navigation={this.props.navigation}>
        <Header
          navigation={this.props.navigation}
          headerTitle="Delivery Areas"
          headerRight={
            <ContainedButton
              startIcon={<AddRounded />}
              onClick={this.handleAddAddressPopupOpen}
            >
              ADD NEW
            </ContainedButton>
          }
        />

        {this.state.loading ? (
          <Loader data-testid="loader" loading={this.state.loading} />
        ) : (
          <section data-testid="delivery-areas-list" className="delivery_areas__wrapper">
            <DataGrid
              columns={this.columns}
              data={this.state.deliveryAreas}
              emptyRowsText="No delivery areas found"
            />
          </section>
        )}

        {this.state.selectedAddress && this.state.openFlagAreaPopup && (
          <FlagAreaPopup
            data-testid="flag-area-popup"
            selectedAddress={this.state.selectedAddress}
            open={this.state.openFlagAreaPopup}
            onClose={this.handleFlagAreaPopupClose}
            submit={this.flagDeliveryArea}
          />
        )}

        {this.state.successPopup.open && (
          <SuccessPopup
            data-testid="success-popup"
            open={this.state.successPopup.open}
            onClose={this.handleSuccessPopupClose}
            message={this.state.successPopup.message || ""}
          />
        )}

        {this.state.errorMessage.open && (
          <ErrorPopup
            data-testid="error-popup"
            open={this.state.errorMessage.open}
            onClose={this.handleErrorPopupClose}
          />
        )}

        <AddAddressPopup
          data-testid="add-address-popup"
          open={this.state.addAddressPopupOpen}
          onClose={this.handleAddAddressPopupClose}
          submit={this.addDeliveryArea}
          getPostCodes={this.getPostCodes}
          postCodeOptions={this.state.postCodeOptions}
        />

        {this.state.updateAddressPopup.data && (
          <UpdateAddressPopup
            data-testid="update-address-popup"
            data={this.state.updateAddressPopup.data}
            open={this.state.updateAddressPopup.open}
            onClose={this.handleUpdateAddressPopupClose}
            submit={this.updateDeliveryArea}
            getPostCodes={this.getPostCodes}
            postCodeOptions={this.state.postCodeOptions}
          />
        )}

        <DeleteAddressPopup
          data-testid="delete-address-popup"
          open={this.state.deleteAddressPopup.open}
          onClose={this.handleDeleteAreaPopupClose}
          submit={this.deleteDeliveryArea}
        />
      </DashboardWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
