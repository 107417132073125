import React from "react";
import { Event, View, ViewsProps } from "react-big-calendar";
import Calendar from "./Calendar";
import "./Scheduler.css";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  error?: {
    message: string;
  };
  recievedView?: View;
  recievedViews?: ViewsProps<Event, object>;
  events: Event[];
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  openLogin: (event: Event) => void;
}

const StaffDayScheduler = React.memo(({ selectedStaff, selectedRole, error, events, updateEvents, recievedView, recievedViews, openShiftPopup, totalShiftHours, openLogin }: Props) => {
  return (
    <div className="scheduler__wrapper">
      <div className="scheduler__content">
        <Calendar
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          recievedView={recievedView}
          recievedViews={recievedViews}
          error={error}
          events={events}
          updateEvents={updateEvents}
          openShiftPopup={openShiftPopup}
          totalShiftHours={totalShiftHours}
          openLogin={openLogin}
        />
      </div>
    </div>
  );
});

export { StaffDayScheduler };
