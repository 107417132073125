Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.contentType = "application/json";

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.patchMethod = "PATCH";

exports.chefDashboardAPI = {
  endPoint: "bx_block_dashboard/chef_dashboard"
};

exports.markReady = "/mark_ready"
exports.markAllReady = "/mark_all_ready"
exports.completeOrder = "/order_cooked_by_chef"

exports.getSubCategoryAPI = "bx_block_dashboard/chef_dashboard/get_sub_category"
exports.getSubSubCategoryAPI = "bx_block_dashboard/chef_dashboard/get_sub_sub_category"
