import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { OrderRevenue as Data } from "../../../../blocks/dashboard/src/dashboards/types";

interface Props {
  orderRevenue: Data;
}

const OrderRevenue = ({ orderRevenue }: Props) => {
  const formatString = (input: string): string => {
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <ResponsiveContainer>
      <BarChart
        data={orderRevenue.last_7_days_revenue}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis
          dataKey="payment_method"
          tickFormatter={(value) => formatString(value)}
        />
        <YAxis />
        <Tooltip
          labelStyle={{
            color: "#1D1D1D",
            fontSize: "14px",
            fontFamily: "Barmeno",
            fontWeight: "bold",
          }}
          contentStyle={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
          itemStyle={{
            color: "#1D1D1D",
            fontSize: "14px",
            fontFamily: "Barmeno",
          }}
          labelFormatter={(value: any) => formatString(value)}
          formatter={(value: any, name: string) => [`£${value}`, formatString(name)]}
        />
        <Legend
          align="left"
          iconSize={10}
          iconType="circle"
          formatter={(value: any) => formatString(value)}
          wrapperStyle={{
            marginLeft: "48px",
          }}
        />
        <Bar
          dataKey="success_amount"
          fill="#34d399"
        />
        <Bar
          dataKey="cancel_amount"
          fill="#e7494e"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default OrderRevenue;
