import React from "react";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { WingoInput } from "../../../Inputs/CustomInputs";
import CustomGreenCheckbox from "../../../CustomGreenCheckBox.web";
import { DeliveryArea } from "../../../../../blocks/cfdeliveryareamanagement1/src/types";
import "./Form.css";

interface Props {
  data: Partial<DeliveryArea>;
  setData: React.Dispatch<React.SetStateAction<Partial<DeliveryArea>>>;
  errors: Record<string, any>;
};

const AddAddressForm = ({ data, setData, errors }: Props) => {
  const {
    area,
    district,
    sector,
    minimum_order,
    delivery_charge,
    flagged,
    flag_reason
  } = data;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (["minimum_order", "delivery_charge"].includes(name)) {
      const previousValue = data[name] as string || ""; // Get the previous value from the state
      const newCharacter = value.slice(-1); // Get the last character added

      // Allow only numeric input, decimal points, or an empty string
      if (
        !newCharacter.match(/[0-9.]/) || // Allow only digits or a decimal point
        (newCharacter === "." && previousValue.includes(".")) // Prevent multiple decimal points
      ) {
        return; // Exit if the input is invalid
      }
    }

    setData({ ...data, [name]: value });
  };

  return (
    <div className="add_address__form">
      <div className="add_address__form__row">
        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">Area</div>
          <WingoInput
            name="area"
            placeholder="Area"
            value={area}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.area}</FormHelperText>
        </FormControl>

        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">District</div>
          <WingoInput
            name="district"
            placeholder="District"
            value={district}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.district}</FormHelperText>
        </FormControl>

        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">Sector</div>
          <WingoInput
            name="sector"
            placeholder="Sector"
            value={sector}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.sector}</FormHelperText>
        </FormControl>
      </div>

      <div className="add_address__form__row">
        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">Minimum Order</div>
          <WingoInput
            name="minimum_order"
            placeholder="Minimum Order"
            value={minimum_order}
            onChange={handleChange}
            startAdornment={
              <div className="start-adornment-icon">£</div>
            }
          />
          <FormHelperText error>{errors.minimum_order}</FormHelperText>
        </FormControl>

        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">Delivery Charge</div>
          <WingoInput
            name="delivery_charge"
            placeholder="Delivery Charge"
            value={delivery_charge}
            onChange={handleChange}
            startAdornment={
              <div className="start-adornment-icon">£</div>
            }
          />
          <FormHelperText error>{errors.delivery_charge}</FormHelperText>
        </FormControl>
      </div>

      <FormControlLabel
        control={
          <CustomGreenCheckbox
            name="flagged"
            checked={flagged}
            onChange={(_event, checked) => setData({ ...data, flagged: checked })}
          />
        }
        label="Flag the delivery area"
        classes={{
          label: "add_address__form__checkbox__label"
        }}
      />

      {flagged && (
        <FormControl required className="add_address__form__input">
          <div data-required={true} className="add_address__form__label">Flag Reason</div>
          <WingoInput
            multiline
            name="flag_reason"
            placeholder="Please provide a reason"
            value={flag_reason}
            onChange={handleChange}
          />
          <FormHelperText error>{errors.flag_reason}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};

export default AddAddressForm;
